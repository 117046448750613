import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ordersApi } from 'services/api';
import { OrderService, OrderServiceInput } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { WIDGET_QUERY_KEY } from '../orders/consts';
import { INVOICE_QUERY_KEY } from '../invoice/consts';
import { DashboardType } from '../../pages/consts';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { MESSAGE_DURATION } from 'consts/common';
import { useInvoiceStore } from 'store/invoiceStore';

const updateItem = (data: OrderService) =>
  ordersApi.updateOrderService(data.id, {
    ...data,
    salesAmount: undefined,
    salesCurrency: undefined,
    costAmount: undefined,
    costCurrency: undefined,
    costInSalesCurrency: undefined,
    profit: undefined
  } as OrderServiceInput);

export const useUpdateOrderService = (dashboardType: DashboardType, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { updateService } = useSettlementDashboardStore(({ updateService }) => ({ updateService }));
  const { current: invoice } = useInvoiceStore(({ current }) => ({ current }));

  return useMutation({
    mutationFn: updateItem,
    onSuccess: async (response) => {
      if (response?.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        if (dashboardType === DashboardType.Dispatcher) {
          await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });
        } else if (dashboardType === DashboardType.Settlement) {
          updateService(response.data);
          await queryClient.invalidateQueries({ queryKey: [INVOICE_QUERY_KEY, invoice?.id] });
        }

        message.success(`${ENTITY_NAME} has been successfully updated`, MESSAGE_DURATION);

        onSuccess && onSuccess();
      }
    }
  });
};
