import React, { useEffect, useMemo, useState } from 'react';
import { Card, Flex, Grid, Table } from 'antd';
import { DICTIONARIES, DictionaryName } from './models';
import { getItemTitle, getTitle } from './utils/getTitle';
import { CONTROL_SIZE, PAGE_SIZE_15 } from 'consts/common';
import { DictionaryDetails } from './components/DictionaryDetails';
import { useDictionaryConfig } from './utils/useDictionaryConfig';
import styled from 'styled-components';
import { isEqual, omit } from 'lodash';
import { useDebounce } from 'helpers';
import { DictionaryHeader } from './components/DictionaryHeader/DictionaryHeader';
import { blue } from '@ant-design/colors';
import { settingsStore } from 'services/settings/SettingsStore';
import { useNavigate, useParams } from 'react-router-dom';
import { DictionaryDetailsErrorBoundary } from './components/DictionaryDetailsErrorBoundary';
import { DictionariesMenu } from './components/DictionariesMenu';
import { Dayjs } from 'dayjs';
import { REBATE_TYPE_OPTIONS } from './components/DictionaryHeader/constants/rebates';

export interface Filter {
  name?: string;
  activeDate?: Dayjs;
  isSpecialPrice?: string;
  rebateType?: REBATE_TYPE_OPTIONS;
}

const { useBreakpoint } = Grid;

const StyledCard = styled(Card)`
  .ant-card-head {
    position: sticky;
    top: 0;
    z-index: 200;
    background: white;
  }

  .ant-card-body {
    padding: 16px;
  }

  .table-row-selected {
    background-color: ${blue[0]};
  }
`;

const dictionariesWithSearch = [
  'airport',
  'country',
  'city',
  'own-price',
  'counterparty',
  'contract',
  'rebate'
];
const dictionariesWithFilter = ['currency-rate', 'own-price', 'rebate'];

const defaultDictionary = 'airport';

export const Dictionaries = () => {
  const [isCreationMode, setCreationMode] = useState(false);

  const navigate = useNavigate();
  const { dictionaryName = defaultDictionary, entityId } = useParams();

  const currentDictionary = useMemo(() => {
    const isExistingDictionaryName =
      dictionaryName && DICTIONARIES.includes(dictionaryName as DictionaryName);

    return (isExistingDictionaryName ? dictionaryName : defaultDictionary) as DictionaryName;
  }, [dictionaryName]);

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<Filter>({});
  const debouncedNameFilter = useDebounce(filter.name, 200);

  const { mainCurrency = '' } = settingsStore.getCurrentTenant();

  const screens = useBreakpoint();

  const {
    columns,
    list,
    current,
    setCurrent,
    onCreate,
    onUpdate,
    onDelete,
    isLoading,
    total,
    getItem
  } = useDictionaryConfig(currentDictionary, {
    name: debouncedNameFilter,
    activeDate: filter.activeDate,
    isSpecialPrice: filter.isSpecialPrice,
    rebateType: filter.rebateType,
    pageSize: PAGE_SIZE_15,
    page
  });

  useEffect(() => {
    currentDictionary === defaultDictionary && navigate(`/dictionaries/${defaultDictionary}`);
  }, []);

  useEffect(() => {
    settingsStore.setCurrentDashboard(undefined);
  }, []);

  useEffect(() => {
    const updatedItem = list.find(({ id }) => id === current?.id);

    // todo: inconsistent data of list and current, discuss with BE once again
    // BE: too much data if add specialParameters.entity to list response
    if (currentDictionary !== 'own-price' && !isEqual(current, updatedItem)) {
      setCurrent(updatedItem);
    }
  }, [list]);

  useEffect(() => {
    setCreationMode(false);
    setCurrent(undefined);
    setFilter(currentDictionary === 'currency-rate' ? { name: mainCurrency } : {});
    setPage(1);
  }, [currentDictionary]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const dataSource = useMemo(
    () => list.map((record) => ({ ...record, key: record.id })),
    [currentDictionary, list]
  );

  const handleAdd = () => {
    setCurrent(undefined);
    setCreationMode(true);
  };

  const handleCancel = () => {
    setCurrent(undefined);
    setCreationMode(false);
    navigate(`/dictionaries/${currentDictionary}`);
  };

  const handleChangeFilter = (name: string, value: string | Dayjs | boolean | undefined | null) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  const isDictionaryWithSearch = useMemo(
    () => dictionariesWithSearch.includes(currentDictionary),
    [currentDictionary]
  );
  const isDictionaryWithFilter = useMemo(
    () => dictionariesWithFilter.includes(currentDictionary),
    [currentDictionary]
  );
  const showPagination = useMemo(
    () =>
      [...dictionariesWithSearch, ...dictionariesWithFilter].includes(currentDictionary) &&
      !!total &&
      total > PAGE_SIZE_15,
    [currentDictionary, total]
  );

  const detailsTitle = useMemo(() => {
    if (currentDictionary === 'currency-rate' && isCreationMode) {
      return 'Cross rate creation';
    }

    if (isCreationMode) {
      return `New ${getTitle(currentDictionary).toLowerCase()}`;
    }

    return getItemTitle(currentDictionary, current);
  }, [currentDictionary, isCreationMode, current]);

  return (
    <Flex style={{ height: 'calc(100vh - 48px)', margin: screens.xxl ? '-12px -20px' : '-12px' }}>
      <DictionariesMenu dictionary={currentDictionary} />

      <Flex
        gap={screens.xxl ? 20 : 12}
        style={{ margin: screens.xxl ? '20px' : '12px', flexGrow: 1 }}>
        <StyledCard
          title={<span data-testid="dictionary-title">{getTitle(currentDictionary)}</span>}
          style={{ flexBasis: 628, flexGrow: 1, overflow: 'hidden', alignItems: 'top' }}>
          <DictionaryHeader
            filter={filter}
            onFilter={handleChangeFilter}
            onAdd={handleAdd}
            showFilter={isDictionaryWithFilter}
            showSearch={isDictionaryWithSearch}
            isLoading={isLoading}
            dictionary={currentDictionary}
          />

          <Table
            columns={columns}
            dataSource={dataSource}
            size={CONTROL_SIZE}
            pagination={
              showPagination && {
                size: CONTROL_SIZE,
                total,
                pageSize: PAGE_SIZE_15,
                showSizeChanger: false,
                onChange: setPage,
                current: page,
                position: ['bottomCenter']
              }
            }
            loading={isLoading}
            scroll={{ y: `calc(100vh - ${showPagination ? 300 : 240}px)` }}
            onRow={(record) => ({
              onClick: () => {
                record.id !== current?.id && setCurrent(record);
                setCreationMode(false);
                navigate(`/dictionaries/${currentDictionary}/${record.id}`);
              }
            })}
            rowClassName={(record) => (record.id == entityId ? 'table-row-selected' : '')}
          />
        </StyledCard>

        <StyledCard
          title={detailsTitle}
          style={{ flexBasis: 528, flexGrow: 1, overflow: 'scroll' }}>
          <DictionaryDetailsErrorBoundary dictionaryName={currentDictionary} entityId={entityId}>
            <DictionaryDetails
              dictionary={currentDictionary}
              data={current}
              setData={setCurrent}
              isCreationMode={isCreationMode}
              setCreationMode={setCreationMode}
              onClose={handleCancel}
              onDelete={onDelete}
              onSubmit={current?.id ? onUpdate : onCreate}
              filter={filter}
              getItem={getItem}
            />
          </DictionaryDetailsErrorBoundary>
        </StyledCard>
      </Flex>
    </Flex>
  );
};
