/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Error, SettlementOrder } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Settlement<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get paginated list of orders containing services and invoice items
   *
   * @tags settlement
   * @name GetSettlementWidget
   * @summary Get settlement dashboard data
   * @request GET:/settlement/widget
   * @secure
   */
  getSettlementWidget = (
    query?: {
      /** Filter by a list of aircraft tail numbers separated by comma. Example - aircraftTailNumbers=UP-HA003,UP-EM019 */
      aircraftTailNumbers?: string;
      /** Filter by a list of location names separated by comma. Example - locationNames=New, Cape town */
      locationNames?: string;
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /** Filter by a list of order types separated by comma. Example - types=GH, ADD */
      types?: string;
      /** Filter by a list of order statuses separated by comma. Example - orderStatuses=ESTD, DONE */
      orderStatuses?: string;
      /** Filter by a list of invoiceNumbers separated by comma. Example - invoiceNumbers=INV15,INR63 etc. */
      invoiceNumbers?: string;
      /** Filter by a list of supplier names separated by comma. Example - supplierNames=New, Cape town */
      supplierNames?: string;
      /** Filter by a list of payer names separated by comma. Example - payerNames=Payer1, Payer2 */
      payerNames?: string;
      /** Filter by startDatetime range, format as 'start,end'. Example - startDatetime=2023-10-26, 2023-10-27 */
      startDatetime?: string;
      /** Filter by endDatetime range, format as 'start,end'. Example - endDatetime=2023-10-26, 2023-10-27 */
      endDatetime?: string;
      /** Filter by a list of flight numbers separated by comma. Example - flightNumbers=FJK1261,FJK1262 */
      flightNumbers?: string;
      /**
       * Filter by a list of orderService service ids separated by comma.
       * @example "1,2,3"
       */
      serviceIds?: string;
      /**
       * Filter by isInProgram property
       * @example true
       */
      isInProgram?: boolean;
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items: SettlementOrder[];
        /** @format int64 */
        total: number;
      },
      Error
    >({
      path: `/settlement/widget`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
