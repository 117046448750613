import { messagesApi } from 'services/emailEngineApi';

export const getAccountMailboxes = async (account: string) => {
  try {
    const response = await messagesApi.getV1AccountAccountMailboxes(account);

    return response.data;
  } catch (error) {
    console.error('Error during getting message account path:', error);
  }
};
