import React, { RefObject, useContext, useEffect } from 'react';
import {
  ContractType,
  DueDateCondition,
  InvoiceType,
  PaymentTerm
} from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';
import { Alert, Checkbox, Flex, Form, Input, InputNumber, InputRef } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { ContractInput } from 'components/ContractInput/ContractInput';
import { InvoiceWithDayJs } from '../../utils';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { InvoiceCurrencyInput } from 'components/CurrencyInput/InvoiceCurrencyInput';
import { useContractStore } from 'store/contractStore';
import { DashboardContext, DashboardType } from 'pages/consts';
import { FormContext } from '../../InvoiceDrawer';
import { FormInstance } from 'antd/es/form';
import { useInputAutoFocus } from 'helpers';

export const InvoiceDetailsForm = () => {
  const [warning, setWarning] = React.useState(false);
  const { type: dashboardType } = useContext(DashboardContext);

  const { form: formInstance, isTouched, setTouched } = useContext(FormContext);
  const form = formInstance as FormInstance<InvoiceWithDayJs>;

  const invoiceNumberRef: RefObject<InputRef> | null = useInputAutoFocus();

  const { current } = useInvoiceStore(({ current }) => ({
    current
  }));

  const { contracts } = useContractStore(({ list }) => ({ contracts: list }));

  const counterpartyId = Form.useWatch(
    current?.type === InvoiceType.Received ? 'supplierId' : 'payerId',
    form
  );
  const contractId = Form.useWatch('contractId', form);

  const isReceived = current?.type === InvoiceType.Received;

  const isLinkedWithOrderServices = current?.orderServices && current?.orderServices.length > 0;

  useEffect(() => {
    const getUpdatedDueDate = () => {
      const formData = form.getFieldsValue();
      const contract = contracts.find((contract) => contract.id === contractId);
      const daysToAdd =
        contract?.dueDateCondition === DueDateCondition.InvoiceDate
          ? formData.invoiceDate
          : formData.receivingOrSendingDate;
      return contract?.paymentTerm === PaymentTerm.Prepayment
        ? formData.invoiceDate
        : daysToAdd?.add(contract?.dueDateCount || 0, 'days');
    };

    if (contractId) {
      const dueDate = getUpdatedDueDate();
      form.setFieldValue('dueDate', dueDate);
    } else {
      form.setFieldValue('dueDate', undefined);
    }
  }, [contractId]);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ justifyContent: 'center' }}
      layout="vertical"
      requiredMark={false}
      form={form}
      autoComplete="off"
      colon={false}
      data-testid="invoice-details-form"
      className="styled-form"
      onValuesChange={(updated: Partial<InvoiceWithDayJs>) => {
        const didChange = form?.isFieldsTouched() !== isTouched;

        if (didChange && form) {
          setTouched && setTouched(form.isFieldsTouched());
        }

        if (updated.payerId || updated.supplierId) {
          form.setFieldValue('contractId', undefined);
          form.setFieldValue('dueDate', undefined);
        }

        if (updated.contractId) {
          form.setFieldValue('currency', undefined);
        }
      }}>
      <Flex gap={12} align="flex-start" justify="start">
        <Form.Item name="invoiceNumber" data-testid="invoiceNumber">
          <Input
            placeholder="Number"
            size={CONTROL_SIZE}
            style={{ width: '114px' }}
            ref={invoiceNumberRef}
          />
        </Form.Item>

        <Form.Item name="isPosted" valuePropName="checked">
          <Checkbox onChange={({ target: { checked } }) => current?.id && setWarning(!checked)}>
            Posted
          </Checkbox>
        </Form.Item>

        <Form.Item name="isDisputed" valuePropName="checked">
          <Checkbox>Disputed</Checkbox>
        </Form.Item>
      </Flex>

      {warning && (
        <Alert
          type="warning"
          showIcon
          message={
            <span>
              All payment purposes linked to this Invoice will be deleted.
              <br />
              Related Amount and Amount Due of Invoice, Advance of Payment will be recalculated.
            </span>
          }
          style={{ marginBottom: 8 }}
        />
      )}

      <Flex gap={12} align="flex-start">
        <div style={{ flexGrow: 0, flexBasis: '50%' }}>
          <CounterpartyInput
            label={isReceived ? 'Supplier' : 'Payer'}
            name={isReceived ? 'supplierId' : 'payerId'}
            onChange={(counterparty) =>
              form.setFieldValue(isReceived ? 'supplierId' : 'payerId', counterparty?.id)
            }
            required
            contractType={isReceived ? ContractType.WithSupplier : ContractType.WithCustomer}
            disabled={
              isLinkedWithOrderServices ||
              (dashboardType === DashboardType.Settlement && current?.isSomeServiceSelected)
            }
          />
        </div>

        <div style={{ flexGrow: 0, flexBasis: '50%' }}>
          <ContractInput
            name="contractId"
            label="Contract"
            counterpartyId={counterpartyId}
            counterpartyType={isReceived ? 'supplier' : 'customer'}
            onSetContract={(contractId) => form?.setFieldValue('contractId', contractId)}
          />
        </div>
      </Flex>

      <Flex gap={12} align="flex-start">
        <Flex gap={12} style={{ flexBasis: '50%' }}>
          <Form.Item
            name="invoiceDate"
            label="Invoice date"
            rules={[{ required: true }]}
            style={{ flexBasis: '33%' }}>
            <StyledDatePicker
              id="invoiceDate"
              format={DATE_FORMAT}
              placeholder="Select invoice date"
              onSetDate={(value) => {
                form && form.setFieldValue('invoiceDate', value);
              }}
            />
          </Form.Item>

          <Form.Item name="supplyDate" label="Supply date" style={{ flexBasis: '33%' }}>
            <StyledDatePicker
              id="supplyDate"
              format={DATE_FORMAT}
              placeholder="Select supply date"
              onSetDate={(value) => {
                form && form.setFieldValue('supplyDate', value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="receivingOrSendingDate"
            label={`${isReceived ? 'Receiving ' : 'Sending'} date`}
            rules={[{ required: true }]}
            style={{ flexBasis: '33%' }}>
            <StyledDatePicker
              id="receivingOrSendingDate"
              format={DATE_FORMAT}
              placeholder={`Select ${isReceived ? 'receiving' : 'sending'} date`}
              onSetDate={(value) => {
                form && form.setFieldValue('receivingOrSendingDate', value);
              }}
            />
          </Form.Item>
        </Flex>

        <Flex gap={12} style={{ flexBasis: '50%' }}>
          <Form.Item
            name="dueDate"
            label="Due date"
            rules={[{ required: true }]}
            style={{ flexBasis: '33%' }}>
            <StyledDatePicker
              id="dueDate"
              format={DATE_FORMAT}
              placeholder="Select due date"
              onSetDate={(value) => {
                form && form.setFieldValue('dueDate', value);
              }}
            />
          </Form.Item>

          <Form.Item name="overdueDays" label="Overdue" style={{ flexBasis: '33%' }}>
            <InputNumber type="number" size={CONTROL_SIZE} addonAfter={'days'} disabled />
          </Form.Item>
        </Flex>
      </Flex>

      {contractId && (
        <Flex gap={12} align="flex-start">
          <Flex gap={12} style={{ flexBasis: '50%' }}>
            <div style={{ flexBasis: '33%' }}>
              <InvoiceCurrencyInput
                contractId={contractId}
                onSetCurrency={(curr) => form.setFieldValue('currency', curr)}
              />
            </div>

            <Form.Item name="amountDue" label="Amount due" style={{ flexBasis: '33%' }}>
              <Input size={CONTROL_SIZE} disabled />
            </Form.Item>

            <Form.Item name="invoiceTotal" label="Invoice total" style={{ flexBasis: '33%' }}>
              <Input size={CONTROL_SIZE} disabled />
            </Form.Item>
          </Flex>

          <Flex gap={12} style={{ flexBasis: '50%' }}>
            <span />
          </Flex>
        </Flex>
      )}

      <Form.Item name="notes" label="Note">
        <Input.TextArea size={CONTROL_SIZE} style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  );
};
