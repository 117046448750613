import { useContext, useMemo } from 'react';
import { MenuProps, message } from 'antd';
import { useOptimisticUpdateMessage } from 'queries/messages/useOptimisticUpdateMessage';
import { DropdownItemKeys } from './consts';
import { FLAG } from '../../consts';
import { LinkActionTypes, LinkedEntityTypes, LinkEntityItem } from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { getSourceLinks } from './helpers/getSourceLinks';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useLinkAction } from '../../helpers/useLinkAction';
import { getDropdownItems } from './helpers/getDropdownItems';
import { FlagList } from 'services/emailEngineApi/data-contracts';
import { useCustomTagUpdate } from './useCustomTagUpdate';
import { useMailboxConfig } from '../../helpers/useMailboxConfig';
import { getColoredTags } from '../../helpers/getColoredTags';
import { map } from 'lodash';
import { DashboardContext, DashboardType } from '../../../../consts';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';

export const useDropdownItemClick = (
  messageId: string,
  isMessageSeen: boolean,
  isMessageDone: boolean,
  isDropdownOpen: boolean,
  flags?: FlagList
) => {
  const { type: dashboardType } = useContext(DashboardContext);

  const { customFlags } = useMailboxConfig();
  const coloredTags = getColoredTags(customFlags);

  const { selectedFlightIds } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlightIds: map(selectedFlights, 'id')
  }));
  const { selectedDispatcherOrderNumbers } = useOrdersDashboardStore(({ selectedOrders }) => ({
    selectedDispatcherOrderNumbers: map(selectedOrders, 'number')
  }));
  const { selectedSettlementOrderNumbers } = useSettlementDashboardStore(({ selectedOrders }) => ({
    selectedSettlementOrderNumbers: selectedOrders
  }));

  const selectedOrderNumbers =
    dashboardType === DashboardType.Dispatcher
      ? selectedDispatcherOrderNumbers
      : selectedSettlementOrderNumbers;

  const {
    data: linksResponse,
    isError,
    isFetching
  } = useGetLinks({ messageIds: messageId }, isDropdownOpen);

  const linkedFlightIds = map(linksResponse?.data.flights || [], 'id');
  const linkedOrderNumbers = map(linksResponse?.data.orders || [], 'number');
  const linkedInvoiceIds = map(linksResponse?.data.invoices || [], 'id');

  const flagMutation = useOptimisticUpdateMessage();
  const linkMutation = useUpdateMessageLinks();
  const toggleFlag = useCustomTagUpdate(messageId, flags);

  const linkAction = useLinkAction({
    isDropdownOpen,
    isError,
    isFetching,
    linkedFlightIds,
    linkedOrderNumbers,
    linkedInvoiceIds,
    selectedFlightIds,
    selectedOrderNumbers
  });
  const dropdownItems = useMemo(
    () => getDropdownItems(linkAction, isMessageSeen, isMessageDone, coloredTags),
    [linkAction]
  );

  const onClickDropdownItem: MenuProps['onClick'] = async (e) => {
    e.domEvent.stopPropagation();

    if (!messageId) return;

    const toggleMessageSeenFlag = () => {
      flagMutation.mutate({
        messageId,
        data: {
          flags: {
            [isMessageSeen ? 'delete' : 'add']: [FLAG.SEEN]
          }
        }
      });
    };

    const updateMessageLinks = async () => {
      if (!linkAction || !['link-selected', 'unlink-selected', 'unlink-all'].includes(linkAction))
        return;

      let action: LinkActionTypes = LinkActionTypes.Link;
      let source: LinkEntityItem[] = [];

      switch (linkAction) {
        case 'link-selected':
          action = LinkActionTypes.Link;
          source = getSourceLinks({
            flightIds: selectedFlightIds,
            orderNumbers: selectedOrderNumbers
          });
          break;
        case 'unlink-selected':
          action = LinkActionTypes.Unlink;
          source = getSourceLinks({
            flightIds: selectedFlightIds,
            orderNumbers: selectedOrderNumbers
          });
          break;
        case 'unlink-all':
          const linkedEntities =
            dashboardType === DashboardType.Dispatcher
              ? {
                  flightIds: linkedFlightIds,
                  orderNumbers: linkedOrderNumbers
                }
              : dashboardType === DashboardType.Settlement
                ? {
                    invoiceIds: linkedInvoiceIds,
                    orderNumbers: linkedOrderNumbers
                  }
                : {};

          action = LinkActionTypes.Unlink;
          source = getSourceLinks(linkedEntities);
          break;
      }

      if (action === LinkActionTypes.Link || action === LinkActionTypes.Unlink) {
        message.loading(`${action}ing in progress...`);
      }

      await linkMutation.mutateAsync({
        action,
        source,
        target: [
          {
            entityType: LinkedEntityTypes.Message,
            entityId: messageId
          }
        ]
      });
    };

    const toggleMessageDoneFlag = () => {
      flagMutation.mutate({
        messageId,
        data: {
          flags: {
            [isMessageDone ? 'delete' : 'add']: [FLAG.DONE]
          }
        }
      });
    };

    switch (e.key) {
      case DropdownItemKeys.Link:
        await updateMessageLinks();
        break;
      case DropdownItemKeys.Read:
        toggleMessageSeenFlag();
        break;
      case DropdownItemKeys.Done:
        toggleMessageDoneFlag();
        break;
      case DropdownItemKeys.LabelAs:
        break;
      default:
        await toggleFlag(e.key);
    }
  };

  return { onClickDropdownItem, dropdownItems };
};
