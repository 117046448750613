import React, { useContext, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, message } from 'antd';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { DashboardContext, DashboardType } from '../../../../consts';
import { useMessageStore } from 'store/messageStore';
import { LinkActionTypes, LinkedEntityTypes } from 'services/api/data-contracts';
import { getSourceLinks } from '../ActionsDropdown/helpers/getSourceLinks';
import { map } from 'lodash';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';

export const LinkAndDoneButton = () => {
  const { type: dashboardType } = useContext(DashboardContext);

  const { selectedMessages, setSelectedMessages, messagesFilter } = useMessageStore(
    ({ selectedMessages, setSelectedMessages, messagesFilter }) => ({
      selectedMessages,
      setSelectedMessages,
      messagesFilter
    })
  );
  const { selectedFlights, setSelectedFlights } = useFlightsDashboardStore(
    ({ selectedFlights, setSelectedFlights }) => ({
      selectedFlights,
      setSelectedFlights
    })
  );
  const { selectedOrders, setSelectedOrders } = useOrdersDashboardStore(
    ({ selectedOrders, setSelectedOrders }) => ({ selectedOrders, setSelectedOrders })
  );

  const handleCloseProgress = () => {
    setSelectedFlights([]);
    setSelectedOrders([]);
    setSelectedMessages([]);
    message.destroy('link-in-process');
  };

  const linkMutation = useUpdateMessageLinks({
    isMessagesInBulkFlagsUpdate: true,
    shouldSetDoneFlag: true,
    onSuccess: handleCloseProgress
  });

  const isTargetEntitiesSelected = useMemo(
    () => [...selectedFlights, ...selectedOrders].length > 0,
    [dashboardType, selectedOrders, selectedFlights]
  );

  const isSettlementDashboard = useMemo(
    () => dashboardType === DashboardType.Settlement,
    [dashboardType]
  );

  const handleLinkAndDone = async () => {
    message.loading({
      content: 'Linking selected messages and moving to done...',
      key: 'link-and-done-in-process'
    });

    const selectedFlightIds = map(selectedFlights, 'id');
    const selectedOrderNumbers = map(selectedOrders, 'number');

    if (selectedFlightIds.length === 0 && selectedOrderNumbers.length === 0) {
      return;
    }

    await linkMutation.mutateAsync({
      action: LinkActionTypes.Link,
      source: getSourceLinks({
        flightIds: selectedFlightIds,
        orderNumbers: selectedOrderNumbers
      }),
      target: selectedMessages.map(({ id }) => ({
        entityType: LinkedEntityTypes.Message,
        entityId: id
      }))
    });
  };

  return (
    <Button
      size={CONTROL_SIZE}
      type="dashed"
      disabled={isSettlementDashboard || messagesFilter.showDone || !isTargetEntitiesSelected}
      onClick={handleLinkAndDone}
      loading={linkMutation.isLoading}>
      Link and Done
    </Button>
  );
};
