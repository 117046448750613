import { FlightStatus, FlightWidgetItem } from 'services/api/data-contracts';
import { Button, Checkbox, Col, Dropdown, Flex, MenuProps, Row } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import React, { useMemo, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { MoreOutlined } from '@ant-design/icons';
import { getNextFlight, getOrderFromFlight, getUTCDate } from 'helpers';
import { useFlightStore } from 'store/flightStore';
import { useGetFlight } from 'queries/flight';
import { useOrderStore } from 'store/orderStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { FlightStatusTag } from './components/FlightDetails/FlightStatusTag';
import { useDrawerStore } from 'store/drawerStore';

const FullWidthRow = styled(Row)`
  width: 100%;
  position: relative;
  padding: 8px 12px;

  .float-btn {
    display: none;
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  &:hover {
    background-color: #fafafa;
  }

  &:hover .float-btn {
    display: block;
  }
`;

const FlexCol = styled(Col)`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  .ant-tag {
    margin-top: 4px !important;
  }
`;

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

interface IProps {
  flight: FlightWidgetItem;
  onClick: (id: number) => void;
}

export const FlightListItem = ({ flight, onClick }: IProps) => {
  const [shouldRequestFlight, setShouldRequestFlight] = useState(false);

  const { selectedFlights, setSelectedFlights, flightsList, setFlightEditMode } =
    useFlightsDashboardStore(
      ({ selectedFlights, setSelectedFlights, flightsList, setFlightEditMode }) => ({
        selectedFlights,
        setSelectedFlights,
        flightsList,
        setFlightEditMode
      })
    );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));
  const { setCurrent } = useFlightStore(({ setCurrent }) => ({ setCurrent }));
  const { setCurrent: setOrder } = useOrderStore(({ setCurrent }) => ({ setCurrent }));
  const { setOrderEditMode } = useOrdersDashboardStore(({ setOrderEditMode }) => ({
    setOrderEditMode
  }));

  const handleSelect = ({ target: { checked, value } }: CheckboxChangeEvent) => {
    const flight = flightsList.find(({ id }) => id === value);

    if (flight) {
      setSelectedFlights(
        checked
          ? [...selectedFlights, flight]
          : selectedFlights.filter(({ id }) => id !== flight.id)
      );
    }
  };

  const isQueryEnabled = useMemo(
    () => Boolean(shouldRequestFlight && flight.id),
    [shouldRequestFlight, flight.id]
  );
  const { data: flightData } = useGetFlight(isQueryEnabled, flight.id);

  const handleCreateNextFlight = () => {
    if (flightData?.data) {
      setCurrent(getNextFlight(flightData?.data));
      setDrawerOpen('flight');
      setFlightEditMode(true);
    }

    setShouldRequestFlight(false);
  };

  const handleCreateOrder = () => {
    if (flightData?.data) {
      setOrder(getOrderFromFlight(flightData.data));
      setOrderEditMode(true);
      setDrawerOpen('order');
    }

    setShouldRequestFlight(false);
  };

  const dropdownItems: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: 'Create',
      children: [
        {
          key: '1-1',
          label: 'Next flight',
          onClick: handleCreateNextFlight
        },
        {
          key: '1-2',
          label: 'Order',
          onClick: handleCreateOrder
        }
      ]
    }
  ];

  const generalInfo = [flight.flightNumber, flight.aircraft?.tailNumber, flight.aircraft?.type];

  const departure = [
    flight.departureAirport?.code,
    getUTCDate(flight.estimatedTimeDeparture).format(DATE_TIME_FORMAT)
  ];

  const arrival = [
    flight.arrivalAirport?.code,
    getUTCDate(flight.estimatedTimeArrival).format(DATE_TIME_FORMAT)
  ];

  return (
    <FullWidthRow justify="space-between">
      <FlexCol span={4}>
        <FlightStatusTag status={flight.status} />

        <Checkbox
          value={flight.id}
          checked={!!selectedFlights.filter(({ id }) => id === flight.id).length}
          onChange={handleSelect}
          id={`flight-${flight.id}`}
          data-testid={`flight-${flight.id}`}
        />
      </FlexCol>

      <Col
        span={20}
        onClick={() => onClick(flight.id)}
        style={{ cursor: 'pointer' }}
        className="flight-info">
        {[generalInfo, departure, arrival].map((dataArray, idx) => (
          <Flex key={idx} gap={12} justify="end">
            {dataArray.map((i, idx) => (
              <DecoratedText key={i + idx} $isCancelled={flight.status === FlightStatus.CNLD}>
                {i}
              </DecoratedText>
            ))}
          </Flex>
        ))}
      </Col>

      <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
        <Button
          shape="circle"
          type="primary"
          icon={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
          onClick={() => setShouldRequestFlight(true)}
          className="float-btn"
          size={CONTROL_SIZE}
        />
      </Dropdown>
    </FullWidthRow>
  );
};
