import React, { useMemo } from 'react';
import { Flex, Table } from 'antd';
import { Program } from 'services/api/data-contracts';
import { getColumns } from './columns';
import { CONTROL_SIZE, DEFAULT_LIMIT } from 'consts/common';
import styled from 'styled-components';
import { DASHBOARD_TOP_PADDING } from '../../../../consts';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { useFlightProgramStore } from 'store/flightProgramStore';

const StyledTable = styled(Table<Program>)`
  .ant-table-thead .ant-table-cell {
    padding: 8px 4px !important;
  }

  .ant-table-placeholder > .ant-table-cell {
    border-bottom: none;
  }

  .table-row {
    cursor: pointer;
  }
`;

const TABLE_HEADER_HEIGHT = 39;

interface IProps {
  headerHeight: number;
  isLoading?: boolean;
}

export const ProgramsTable = ({ headerHeight, isLoading }: IProps) => {
  const { setOpenProgramId, list } = useFlightProgramStore();
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const navigate = useNavigate();

  const tableHeight = useMemo(
    () => window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight - TABLE_HEADER_HEIGHT,
    [window.innerHeight, headerHeight]
  );

  const dataSource = useMemo(
    () => list.map((program) => ({ ...program, key: program.id })),
    [list]
  );
  const columns = useMemo(() => getColumns(), []);

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      size={CONTROL_SIZE}
      loading={isLoading}
      data-testid={isLoading ? 'table-loading' : 'table'}
      pagination={false}
      scroll={{ y: tableHeight }}
      rowClassName="table-row"
      onRow={(record) => ({
        onClick: (e) => {
          const isCheckbox = 'checked' in e.target;

          if (!isCheckbox) {
            navigate(`/dashboard/dispatcher/flightProgram/${record.id}${window.location.search}`);
            setOpenProgramId(record.id);
            setDrawerOpen('flightProgram');
          }
        }
      })}
      summary={(orders) => {
        if (orders.length === DEFAULT_LIMIT) {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={9}>
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: 100, width: '100%', textAlign: 'center', color: '#00000073' }}>
                    {DEFAULT_LIMIT} programs are displayed. <br />
                    Change the filters to find the one you are looking for.
                  </Flex>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
};
