import React, { useEffect, useMemo } from 'react';
import { ConfigProvider, Empty, Form, InputNumber, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { getOrderTerm, getUTCDate, getAmountValue } from 'helpers';
import { useGetOwnPricesForInvoiceItem } from 'queries/ownPrice/useGetOwnPricesForInvoiceItem';

interface IProps {
  payerContractId: number;
  serviceNameId: number;
  orderServiceId?: number;
  isIssuedInvoice: boolean;
  isOwnPrice: boolean;
  onChange: (price?: number, ownPriceId?: number) => void;
  invoiceSupplyDate?: string;
  label?: string;
  itemName?: number;
  ownPriceId?: number;
}

export const PriceInput = ({
  payerContractId,
  serviceNameId,
  orderServiceId,
  isIssuedInvoice,
  invoiceSupplyDate,
  onChange,
  isOwnPrice,
  label,
  itemName,
  ownPriceId
}: IProps) => {
  const { isFetching, data: ownPricesResponse } = useGetOwnPricesForInvoiceItem(
    payerContractId,
    serviceNameId,
    orderServiceId,
    invoiceSupplyDate,
    Boolean(isIssuedInvoice && isOwnPrice && serviceNameId)
  );

  const ownPriceOptions = useMemo(
    () =>
      ownPricesResponse
        ? ownPricesResponse?.data.items?.map((ownPrice) => ({
            description: [
              ownPrice.name,
              getOrderTerm(getUTCDate(ownPrice.startDate), getUTCDate(ownPrice.endDate))
            ]
              .filter((i) => !!i)
              .join(' | '),
            price: [
              `${ownPrice.currency} ${getAmountValue(ownPrice.price)} per ${ownPrice.unitOfMeasure.shortName}`,
              ownPrice.convertedPrice &&
                `${ownPrice.currency} ${ownPrice.convertedPrice} per ${ownPrice.convertedPrice.uomShortName}`
            ]
              .filter((i) => !!i)
              .join(' → '),
            value: ownPrice.id,
            label: ownPrice.convertedPrice ? ownPrice.convertedPrice.price : ownPrice.price
          }))
        : [],
    [ownPricesResponse]
  );

  useEffect(() => {
    if (isOwnPrice && isIssuedInvoice && ownPriceOptions?.length === 1) {
      const { value, label } = ownPriceOptions[0];
      onChange(label, value);
    }
  }, [serviceNameId, isOwnPrice, isFetching]);

  useEffect(() => {
    if (isOwnPrice && isIssuedInvoice && !ownPriceOptions?.length && !isFetching) {
      onChange(undefined, undefined);
    }
  }, [serviceNameId]);

  const isOwnPriceSelectable = useMemo(
    () => isOwnPrice && isIssuedInvoice && !!ownPriceOptions?.length,
    [isOwnPrice, isIssuedInvoice, ownPriceOptions]
  );

  return (
    <>
      <Form.Item
        label={label}
        name={typeof itemName === 'number' ? [itemName, 'ownPriceId'] : 'ownPriceId'}
        hidden={!isOwnPriceSelectable}
        rules={[{ required: isOwnPriceSelectable, message: 'Is required' }]}
        style={{ flexBasis: 130, margin: 0 }}>
        <ConfigProvider
          renderEmpty={() => (
            <Empty description="Own prices not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}>
          <Select
            size={CONTROL_SIZE}
            placeholder="0.00"
            options={ownPriceOptions}
            disabled={ownPriceOptions?.length === 1}
            style={{ width: '100%' }}
            loading={isFetching}
            popupMatchSelectWidth={false}
            value={ownPriceId}
            optionRender={(option) => (
              <div aria-label={option.data.label?.toString()}>
                <span>{option.data.description}</span>
                <br />
                <span style={{ color: '#00000073' }}>{option.data.price}</span>
              </div>
            )}
            onChange={(ownPriceId) => {
              const ownPrice = ownPriceOptions?.find(({ value }) => value === ownPriceId);
              ownPrice && onChange(ownPrice.label, ownPrice.value);
            }}
          />
        </ConfigProvider>
      </Form.Item>

      <Form.Item
        label={label}
        name={typeof itemName === 'number' ? [itemName, 'price'] : 'price'}
        hidden={isOwnPriceSelectable}
        rules={[
          {
            required: !isIssuedInvoice,
            message: `Is required${isOwnPrice ? '. Price not found.' : ''}`
          }
        ]}
        style={{ flexBasis: 130, margin: 0 }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          placeholder="0.00"
          precision={2}
          style={{ width: '100%' }}
          min={0}
          onChange={(value) => onChange(Number(value))}
        />
      </Form.Item>
    </>
  );
};
