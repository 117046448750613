import { Links } from '../../../components/Links';
import { Spin } from 'antd';
import { InvoiceDetails } from './InvoiceDetails';
import React from 'react';
import styled from 'styled-components';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useGetInvoice } from 'queries/invoice';
import { useInvoiceStore } from 'store/invoiceStore';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

interface IProps {
  isLoading?: boolean;
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDrawerContent = ({ isLoading, setAddServiceDrawerOpen }: IProps) => {
  const { current, isInvoiceEditMode, links } = useInvoiceStore(
    ({ current, isInvoiceEditMode, links }) => ({
      current,
      isInvoiceEditMode,
      links
    })
  );

  const linksQuery = useGetLinks({ invoiceIds: current?.id?.toString() }, !!current?.id);
  const hasLinksWithMessages = links.messages && links.messages?.length > 0;

  const { isFetching } = useGetInvoice(current?.id);

  return (
    <>
      {!isInvoiceEditMode && hasLinksWithMessages && (
        <LinksWrapper>
          <Links
            entityType="invoice"
            targetEntityId={current?.id}
            isLoading={linksQuery.isFetching}
            linkedMessages={links.messages}
          />
        </LinksWrapper>
      )}

      {isLoading || isFetching ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <InvoiceDetails setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
      )}
    </>
  );
};
