import { useQuery } from '@tanstack/react-query';
import { aircraftsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useAircraftStore } from 'store/aircraftStore';

export interface AircraftsApiFilter {
  operatorId?: number;
}

export const fetchList = async (filter: AircraftsApiFilter) =>
  await aircraftsApi.getAircrafts(filter);

export const useGetAircrafts = (filter: AircraftsApiFilter) => {
  const { setList } = useAircraftStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true
  });
};
