import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { ContractPaymentType, CreditNoteItem, Payment } from 'services/api/data-contracts';
import { Flex } from 'antd';
import { getAmountValue } from 'helpers';
import { PercentageOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';

export const getColumns = (): ColumnsType<
  CreditNoteItem & {
    payment?: Payment;
  }
> => [
  {
    title: '',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 40,
    render: (_, record) => (
      <PercentageOutlined
        style={{
          color: record.payment?.type === ContractPaymentType.Incoming ? magenta[6] : geekblue[3]
        }}
      />
    )
  },
  {
    title: '№',
    dataIndex: 'creditNoteNumber',
    key: 'creditNoteNumber',
    render: (_, record) => record.payment?.creditNoteNumber
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value) => <Flex justify="end">{getAmountValue(value, 4)}</Flex>
  },

  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => <Flex justify="end">{getAmountValue(value, 2)}</Flex>
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (value) => <Flex justify="end">{getAmountValue(value, 2)}</Flex>
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (value) => (
      <Flex justify="end" style={{ marginRight: 8 }}>
        {getAmountValue(value, 2)}
      </Flex>
    )
  }
];
