import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Divider, Drawer, Flex, Form, Input, InputNumber, Skeleton } from 'antd';
import { useInvoiceStore } from 'store/invoiceStore';
import styled from 'styled-components';
import { useGetInvoiceItem } from 'queries/invoiceItems';
import { RolledUpItemsTable } from './RolledUpItemsTable';
import { CONTROL_SIZE, SERVER_DATE_FORMAT } from 'consts/common';
import { getAmountValue, getUTCDate } from 'helpers';
import { useForm } from 'antd/es/form/Form';
import { InvoiceItemHydratedWithRolledUp, InvoiceType } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { RateInput } from './RateInput';
import { useGetCurrencyRates } from 'queries/currencyRate';
import { HeaderButtons } from './HeaderButtons';
import { useUpdateInvoiceItems } from 'queries/invoiceItems';
import { OwnPriceDescriptions } from './OwnPriceDescriptions';
import { useGetOwnPrice } from 'queries/ownPrice';
import { DashboardContext, DashboardType } from '../../../consts';
import { UnitsOfMeasureInput } from 'components/UnitsOfMeasureInput/UnitsOfMeasureInput';
import { getUOMConvertionPreview } from './helpers/getUOMConvertionPreview';
import { FUEL_SERVICE_NAME } from 'consts/order';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    padding: 8px;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 100px;

  .ant-skeleton-title {
    margin: 7px 0;
  }
`;

type InvoiceItem = Omit<InvoiceItemHydratedWithRolledUp, 'rateDate'> & { rateDate?: Dayjs };

export const CalculatorDrawer = ({ isFullScreenMode }: { isFullScreenMode?: boolean }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [extraAmount, setExtraAmount] = useState(0);
  const [totalWithExtra, setTotalWithExtra] = useState(0);
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState('');
  const [correctedCurrencyRate, setCorrectedCurrencyRate] = useState<number | undefined>(undefined);

  const { type: dashboardType } = useContext(DashboardContext);

  const { current, currentInvoiceItem, setCurrentInvoiceItem } = useInvoiceStore(
    ({ current, currentInvoiceItem, setCurrentInvoiceItem }) => ({
      current,
      currentInvoiceItem,
      setCurrentInvoiceItem
    })
  );
  const { isCalculatorDrawerOpen, setDrawerClosed } = useDrawerStore(
    ({ openDrawers, setDrawerClosed }) => ({
      isCalculatorDrawerOpen: openDrawers.includes('calculator'),
      setDrawerClosed
    })
  );

  const [form] = useForm<InvoiceItem>();
  const rateDate = Form.useWatch('rateDate', form);
  const extra = Form.useWatch('extra', form);

  const isOwnPriceItem = !!currentInvoiceItem?.isOwnPrice && !!currentInvoiceItem.ownPriceId;

  const currentService = useMemo(
    () =>
      current?.invoiceItems.filter((i) => i.id === currentInvoiceItem?.id)?.[0]?.orderService
        ?.service,
    [current, currentInvoiceItem]
  );
  const isFuelService = useMemo(() => currentService?.name === FUEL_SERVICE_NAME, [currentService]);

  const UOMPreview = useMemo(
    () =>
      selectedUnitOfMeasure &&
      getUOMConvertionPreview(selectedUnitOfMeasure, currentService, currentInvoiceItem),
    [selectedUnitOfMeasure, currentService, currentInvoiceItem]
  );

  const { isFetching } = useGetInvoiceItem(currentInvoiceItem?.id);
  const { data: ownPriceData, isFetching: isOwnPriceFetching } = useGetOwnPrice(
    currentInvoiceItem?.ownPriceId,
    !!currentInvoiceItem?.ownPriceId
  );
  const ownPrice = useMemo(() => ownPriceData?.data, [ownPriceData]);
  const sourceCurrency = useMemo(
    () =>
      isOwnPriceItem
        ? ownPrice?.currency
        : currentInvoiceItem?.rolledUpInvoiceCurrency || undefined,
    [isOwnPriceItem, ownPrice, currentInvoiceItem]
  );
  const { data: rateData, isFetching: isRateFetching } = useGetCurrencyRates(
    {
      page: 1,
      pageSize: 1,
      sourceCurrency,
      targetCurrency: current?.currency,
      includeNearestDates: true,
      date: rateDate?.toString()
    },
    !!rateDate
  );
  const currencyRate = useMemo(
    () => rateData?.data.items[0]?.exchangeRates[current?.currency as string],
    [rateData]
  );
  useEffect(() => {
    setExtraAmount(currentInvoiceItem?.extraAmountInCurrency || 0);
    setTotalAmount(currentInvoiceItem?.rolledUpTotalInCurrency || 0);
    setTotalWithExtra(currentInvoiceItem?.rolledUpTotalExtraInCurrency || 0);

    if (currentInvoiceItem) {
      form.setFieldsValue({
        ...currentInvoiceItem,
        extra: currentInvoiceItem.extra || 0,
        rateDate: currentInvoiceItem.rateDate ? getUTCDate(currentInvoiceItem.rateDate) : undefined
      });
    }
  }, [currentInvoiceItem]);

  useEffect(() => {
    if (!currencyRate || !currentInvoiceItem) {
      return;
    }

    const priceInOriginalCurrency =
      isOwnPriceItem && ownPrice
        ? currentInvoiceItem.quantity * ownPrice.price
        : currentInvoiceItem?.rolledUpTotal || 0;

    const total = priceInOriginalCurrency * currencyRate;
    setTotalAmount(total);

    const correctedCurrencyRate = currencyRate * (1 + (extra || 0) / 100);
    setCorrectedCurrencyRate(correctedCurrencyRate);
    setTotalWithExtra(priceInOriginalCurrency * correctedCurrencyRate);
  }, [currencyRate, extra]);

  useEffect(() => {
    setExtraAmount(totalWithExtra - totalAmount);
  }, [totalWithExtra, totalAmount]);

  useEffect(() => {
    if (isFuelService && !!selectedUnitOfMeasure) {
      const quantity = Number(
        getUOMConvertionPreview(selectedUnitOfMeasure, currentService, currentInvoiceItem)
      );
      quantity && form.setFieldsValue({ quantity });
    }
  }, [currentInvoiceItem, isFuelService, selectedUnitOfMeasure, currentService]);

  useEffect(
    () => () => {
      form.setFieldsValue({});
      form.resetFields();
    },
    []
  );

  const updateMutation = useUpdateInvoiceItems({ invoiceId: current?.id });

  const handleCloseDrawer = () => {
    setDrawerClosed('calculator');
    setCurrentInvoiceItem(undefined);
  };

  const handleSubmit = () => {
    try {
      const data = form.getFieldsValue();

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { unitOfMeasure, ...current } = currentInvoiceItem as InvoiceItemHydratedWithRolledUp;

      updateMutation.mutate([
        {
          ...current,
          ...data,
          rateDate: data.rateDate ? data.rateDate.format(SERVER_DATE_FORMAT) : undefined
        }
      ]);

      handleCloseDrawer();
    } catch (err) {
      console.log(err);
    }
  };

  const isIssuedInvoice = useMemo(() => current?.type === InvoiceType.Issued, [current]);
  const itemCurrency = isOwnPriceItem
    ? ownPrice?.currency
    : currentInvoiceItem?.rolledUpInvoiceCurrency;
  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  return (
    <StyledDrawer
      title={currentInvoiceItem?.serviceName?.name}
      open={isCalculatorDrawerOpen}
      closable={false}
      width={560}
      onClose={handleCloseDrawer}
      extra={
        <HeaderButtons
          isLoading={isFetching || isRateFetching}
          onSubmit={handleSubmit}
          onClose={() => {
            setDrawerClosed('calculator');
            setCurrentInvoiceItem(undefined);
          }}
        />
      }
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      {currentInvoiceItem && !isOwnPriceItem && (
        <RolledUpItemsTable isLoading={isFetching} invoiceItem={currentInvoiceItem} />
      )}

      {currentInvoiceItem && isOwnPriceItem && isIssuedInvoice && (
        <OwnPriceDescriptions
          invoiceItem={currentInvoiceItem}
          ownPrice={ownPrice}
          isLoading={isOwnPriceFetching}
        />
      )}

      <Form
        style={{ marginTop: 20 }}
        form={form}
        requiredMark={false}
        colon={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="styled-form">
        <Form.Item name="quantity" hidden>
          <Input />
        </Form.Item>
        {isFuelService && !!currentInvoiceItem && !isOwnPriceFetching && !isFetching && (
          <Flex gap={12}>
            <UnitsOfMeasureInput
              name="unitOfMeasureId"
              label="Convert to"
              required={false}
              isFuel={isFuelService}
              currentUOM={currentInvoiceItem.unitOfMeasure.shortName}
              onChange={(_, shortName) => {
                if (shortName) {
                  setSelectedUnitOfMeasure(shortName);
                }
              }}
            />
            {selectedUnitOfMeasure && (
              <span
                style={{ marginTop: 4, fontFamily: 'PingFang SC', color: 'rgba(0, 0, 0, 0.45)' }}>
                {UOMPreview ? `${UOMPreview} ${selectedUnitOfMeasure}` : ''}
              </span>
            )}
          </Flex>
        )}
        {!!itemCurrency && current?.currency !== itemCurrency && (
          <>
            <RateInput
              onSetDate={(value) => form.setFieldValue('rateDate', value)}
              invoiceCurrency={current?.currency}
              sourceCurrency={sourceCurrency}
              amount={getAmountValue(totalAmount)}
              isLoading={isRateFetching}
              rate={currencyRate}
              disabled={!isIssuedInvoice}
            />

            <Flex justify="space-between">
              <Flex gap={12} style={{ flexGrow: 1 }}>
                <Form.Item name="extra" label="Extra" style={{ flexBasis: 220 }}>
                  <InputNumber
                    type="number"
                    style={{ width: '100%' }}
                    size={CONTROL_SIZE}
                    disabled={!isIssuedInvoice}
                  />
                </Form.Item>

                <StyledSkeleton loading={isRateFetching} paragraph={false}>
                  <span
                    style={{
                      marginTop: 4,
                      fontFamily: 'PingFang SC',
                      color: 'rgba(0, 0, 0, 0.45)'
                    }}>
                    {correctedCurrencyRate &&
                      `${sourceCurrency || ''} ${getAmountValue(correctedCurrencyRate, 6) || ''}`}
                  </span>
                </StyledSkeleton>
              </Flex>

              <span style={{ fontWeight: 500, marginTop: 4 }}>
                {current?.currency} {getAmountValue(extraAmount)}
              </span>
            </Flex>
          </>
        )}
      </Form>

      <Divider />

      <div style={{ textAlign: 'end', width: '100%', fontWeight: 500, fontSize: 20 }}>
        {current?.currency} {getAmountValue(totalWithExtra)}
      </div>
    </StyledDrawer>
  );
};
