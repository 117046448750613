import React, { useState } from 'react';
import { useGetInvoiceTemplates } from 'queries/invoice';
import { invoicesApi } from 'services/api';
import {
  Error,
  InvoiceTemplatesListResponse,
  LinkedEntityTypes,
  ReportFormat
} from 'services/api/data-contracts';
import { ExportPdfPopover } from '../../../../components/ExportPdfPopover/ExportPdfPopover';
import { useMessageStore } from 'store/messageStore';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

interface IProps {
  invoiceId: number;
  isLoading: boolean;
}

export const ExportPopover = ({ isLoading, invoiceId }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [format, setFormat] = useState(ReportFormat.Pdf);
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);

  const { setNewMessageMode, setCurrentMessage, setPendingEntityToLinkFromTemplate } =
    useMessageStore();

  const templatesQueryResult = useGetInvoiceTemplates(isOpen);

  const fetchReport = async () => {
    const response = await invoicesApi.generateInvoiceReport(
      invoiceId,
      { templateId, format },
      {
        format: 'arraybuffer',
        headers: {
          Accept:
            format === ReportFormat.Pdf
              ? 'application/pdf, application/json, application/text'
              : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      }
    );

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });

    const contentDisposition = response.headers['content-disposition'] || '';
    const match = contentDisposition.match(/filename="(.+)"/);
    const filename = match ? match[1] : 'report.pdf';

    return { blob, filename, contentType };
  };

  const handleAttachToMessage = (filename: string, contentType: string) => {
    setNewMessageMode('withInvoiceAttachment');
    setPendingEntityToLinkFromTemplate({
      entityType: LinkedEntityTypes.Invoice,
      entityId: invoiceId
    });
    setCurrentMessage({
      subject: `Attached ${filename}`,
      attachments: [{ filename, contentType }]
    });
  };

  return (
    <ExportPdfPopover
      entityName="invoice"
      templatesQueryResult={
        templatesQueryResult as UseQueryResult<
          AxiosResponse<InvoiceTemplatesListResponse, unknown>,
          Error
        >
      }
      isOpen={isOpen}
      onSetOpen={setOpen}
      templateId={templateId}
      onSetTemplateId={setTemplateId}
      format={format}
      onSetFormat={setFormat}
      isEntityLoading={isLoading}
      onFetchReport={fetchReport}
      onAttachToMessage={handleAttachToMessage}
    />
  );
};
