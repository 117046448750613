import React, { useContext, useMemo, useState } from 'react';
import { EntityType, entityTypes } from './Footer';
import { useDashboardStore } from 'store/dashboardStore';
import { Button, Flex, Skeleton, Tag, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT_SHORT } from 'consts/common';
import {
  FlightWidgetItem,
  LinkActionTypes,
  LinkedEntityTypes,
  LinkedFlight,
  LinkedInvoice,
  LinkedOrder
} from 'services/api/data-contracts';
import styled from 'styled-components';
import { DownOutlined, MailOutlined, SendOutlined } from '@ant-design/icons';
import { getOrderTerm, getUTCDate, getOrderIcon } from 'helpers';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightStore } from 'store/flightStore';
import { useOrderStore } from 'store/orderStore';
import { GoUnlink } from 'react-icons/go';
import { mapEntityTypeToLinkedEntityType } from './utils';
import { IoLogoUsd as UsdIcon } from 'react-icons/io5';
import { DashboardContext, DashboardType } from '../../consts';
import { useMessageStore } from 'store/messageStore';
import { orange } from '@ant-design/colors';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';

const LinkButton = styled(Button)`
  padding: 0;

  span {
    color: black;
    font-weight: 400;
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  gap: 6px;

  span:has(.text) {
    flex-grow: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-inline-start: 0 !important;
  }

  .ant-tag-close-icon {
    display: inline-flex;
    align-self: center;
    margin: 0;
    font-size: 16px;
  }
`;

interface IProps {
  entityType: EntityType;
  targetEntityId?: string | number;
  isLoading: boolean;
  linkedFlights?: LinkedFlight[];
  linkedOrders?: LinkedOrder[];
  linkedMessages?: string[];
  linkedInvoices?: LinkedInvoice[];
}

export const Links = ({
  entityType,
  targetEntityId,
  isLoading,
  linkedFlights = [],
  linkedOrders = [],
  linkedMessages = [],
  linkedInvoices = []
}: IProps) => {
  const [isOpen, setOpen] = useState(false);

  const { type: dashboardType } = useContext(DashboardContext);

  const { setLinkFilters } = useDashboardStore(({ setLinkFilters }) => ({
    setLinkFilters
  }));
  const { setOrdersFilter, setSelectedOrders, ordersList } = useOrdersDashboardStore(
    ({ setOrdersFilter, setSelectedOrders, ordersList }) => ({
      setOrdersFilter,
      setSelectedOrders,
      ordersList
    })
  );
  const { order } = useOrderStore(({ current }) => ({ order: current }));
  const { setFlightsFilter, setSelectedFlights, setFlightsPageNumber } = useFlightsDashboardStore(
    ({ setFlightsFilter, setSelectedFlights, setFlightsPageNumber }) => ({
      setFlightsFilter,
      setSelectedFlights,
      setFlightsPageNumber
    })
  );
  const { flight } = useFlightStore(({ current }) => ({ flight: current }));
  const { setSelectedMessages, currentMessage } = useMessageStore(
    ({ setSelectedMessages, currentMessage }) => ({
      setSelectedMessages,
      currentMessage
    })
  );

  const linkMutation = useUpdateMessageLinks();

  const filterTargets = entityTypes.filter((type) => type !== entityType);

  const handleFilter = () => {
    setLinkFilters(filterTargets.map((target) => `${entityType}-${target}`));

    if (filterTargets.includes('orders')) {
      setOrdersFilter({});
    }

    if (filterTargets.includes('flights')) {
      setFlightsFilter({});
      setFlightsPageNumber(1);
    }

    switch (entityType) {
      case 'orders':
        setSelectedOrders(ordersList.filter(({ number }) => number === order?.number));
        break;
      case 'flights':
        setSelectedFlights([flight as FlightWidgetItem]);
        break;
      case 'messages':
        setSelectedMessages([
          { id: currentMessage?.id, subject: currentMessage?.subject, flags: currentMessage?.flags }
        ]);
        break;
    }
  };

  const unlinkIcon = useMemo(
    () =>
      entityType === 'messages' ? (
        <Tooltip title="Unlink">
          <GoUnlink size={12} />
        </Tooltip>
      ) : null,
    [entityType]
  );

  const handleUnlink = async (source: {
    entityId: string | number;
    entityType: LinkedEntityTypes;
  }) => {
    if (targetEntityId && source.entityId && source.entityType) {
      linkMutation.mutate({
        action: LinkActionTypes.Unlink,
        target: [
          {
            entityType: mapEntityTypeToLinkedEntityType(entityType),
            entityId: targetEntityId
          }
        ],
        source: [source]
      });
    }
  };

  const links = [...linkedFlights, ...linkedOrders, ...linkedMessages, ...linkedInvoices];

  const flightTags = linkedFlights?.map((flight) => (
    <StyledTag
      color="blue"
      icon={<SendOutlined />}
      key={flight.id}
      closeIcon={unlinkIcon}
      onClose={() => handleUnlink({ entityId: flight.id, entityType: LinkedEntityTypes.Flight })}>
      <span className="text">{flight.flightNumber}</span>
    </StyledTag>
  ));

  const messagesTags = linkedMessages?.map((message) => (
    <StyledTag
      color="magenta"
      icon={<MailOutlined />}
      key={message}
      closeIcon={<GoUnlink />}
      onClose={() => handleUnlink({ entityId: message, entityType: LinkedEntityTypes.Message })}>
      <span className="text">{message}</span>
    </StyledTag>
  ));

  const orderTags = linkedOrders?.map((order) => {
    const location = order.location
      ? 'code' in order.location
        ? order.location.code
        : order.location.name
      : undefined;

    const props = [
      order.orderType,
      location,
      getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime))
    ]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        color="orange"
        icon={getOrderIcon(order.orderType, orange[6])}
        key={order.number}
        closeIcon={unlinkIcon}
        onClose={() =>
          handleUnlink({ entityId: order.number, entityType: LinkedEntityTypes.Order })
        }>
        <span className="text">{props}</span>
      </StyledTag>
    );
  });

  const invoiceTags = linkedInvoices?.map((invoice) => {
    const props = [
      invoice.invoiceNumber,
      invoice.type,
      getUTCDate(invoice.receivingOrSendingDate).format(DATE_FORMAT_SHORT)
    ]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        color="purple"
        icon={<UsdIcon />}
        key={invoice.id}
        closeIcon={unlinkIcon}
        onClose={() =>
          handleUnlink({ entityId: invoice.id, entityType: LinkedEntityTypes.Invoice })
        }>
        {props}
      </StyledTag>
    );
  });

  if (isLoading) {
    return (
      <div data-testid="loader">
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 200 }} />
      </div>
    );
  }

  if (linkMutation.isLoading) {
    return (
      <div data-testid="unlink-loader">
        <Flex>
          <Skeleton.Input size={CONTROL_SIZE} style={{ width: 20, margin: '10px 8px 8px 0' }} />
          <Skeleton.Input size={CONTROL_SIZE} style={{ width: 20, margin: '10px 8px 8px 0' }} />
        </Flex>
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 200 }} />
      </div>
    );
  }

  if (!links.length) {
    return null;
  }

  return (
    <Flex gap={8} vertical data-testid="links">
      <Flex align="center" gap={12}>
        <Flex gap={4} align="center">
          <LinkButton
            type="link"
            onClick={() => setOpen((prev) => !prev)}
            data-testid="expand-links">
            {links.length} {links.length === 1 ? 'link' : 'links'} <DownOutlined />
          </LinkButton>
        </Flex>
        <Button
          type="link"
          size={CONTROL_SIZE}
          onClick={handleFilter}
          disabled={dashboardType !== DashboardType.Dispatcher}>
          Apply as a filter
        </Button>
      </Flex>

      {isOpen && (
        <Flex wrap="wrap">
          {flightTags}
          {orderTags}
          {messagesTags}
          {invoiceTags}
        </Flex>
      )}
    </Flex>
  );
};
