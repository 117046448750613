import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useOrderStore } from 'store/orderStore';
import { useOrderDetails } from './useOrderDetails';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderDetails } from './OrderDetails/OrderDetails';
import { Links } from '../Links';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

export const OrderDrawerContent = ({ isLoading }: { isLoading: boolean }) => {
  const { current } = useOrderStore(({ current }) => ({ current }));
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));

  useOrderDetails();

  const { data: links, isFetching: areLinksLoading } = useGetLinks(
    { orderNumbers: current?.number?.toString() },
    !!current?.number
  );
  const linkedFlights = links?.data.flights || [];
  const linkedMessages = links?.data.messages || [];

  return (
    <>
      {!isOrderEditMode && [...linkedFlights, ...linkedMessages].length > 0 && (
        <LinksWrapper>
          <Links
            entityType="orders"
            targetEntityId={current?.number as string}
            isLoading={areLinksLoading}
            linkedFlights={linkedFlights}
            linkedMessages={linkedMessages}
          />
        </LinksWrapper>
      )}

      {current ? (
        <OrderDetails type={current.type} order={current} isLoading={isLoading} />
      ) : (
        <SpinWrapper>
          <Spin data-testid="loading-spinner" />
        </SpinWrapper>
      )}
    </>
  );
};
