import React from 'react';
import { InvoiceType, SettlementInvoiceItem } from 'services/api/data-contracts';
import { FormInstance } from 'antd/es/form';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';
import { Form, Input, InputNumber } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { PriceInput } from '../../InvoicesWidget/components/InvoiceDetails/accordions/InvoiceItemsBlock/PriceInput';
import { VatRateInput } from 'components/VatRateInput/VatRateInput';

interface EditableCellProps {
  isEditMode: boolean;
  dataIndex: string;
  children: React.ReactNode;
  record: SettlementInvoiceItem;
  form: FormInstance<SettlementInvoiceItem>;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  isEditMode,
  dataIndex,
  children,
  record,
  form,
  ...restProps
}) => {
  let inputNode = null;

  switch (dataIndex) {
    case 'serviceNameId':
      inputNode = (
        <ServiceNameInput
          name="serviceNameId"
          required
          onChange={(id, unitOfMeasure) => {
            const shortName = unitOfMeasure.shortName;
            form.setFieldsValue({ serviceNameId: id, unitOfMeasure: { shortName } });

            if (record.isOwnPrice && record.invoice.type === InvoiceType.Issued) {
              form.validateFields([['price']]);
            }
          }}
        />
      );
      break;
    case 'unitOfMeasure':
      inputNode = (
        <Form.Item name={['unitOfMeasure', 'shortName']} style={{ margin: 0 }}>
          <Input size={CONTROL_SIZE} disabled style={{ padding: 0 }} />
        </Form.Item>
      );
      break;
    case 'quantity':
      inputNode = (
        <Form.Item
          name="quantity"
          rules={[{ required: true, message: 'Is required' }]}
          style={{ margin: 0 }}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.00"
            style={{ width: '100%' }}
            min={0}
            precision={4}
            formatter={(value) => {
              const coercedNumber = Number(value);
              return coercedNumber.toString();
            }}
          />
        </Form.Item>
      );
      break;
    case 'price':
      inputNode = (
        <PriceInput
          payerContractId={record.invoice.contractId}
          isIssuedInvoice={record.invoice.type === InvoiceType.Issued}
          isOwnPrice={!!record.isOwnPrice}
          serviceNameId={form.getFieldValue('serviceNameId')}
          orderServiceId={record.orderServiceId}
          onChange={(price, ownPriceId) => {
            form.setFieldsValue({ ownPriceId, price });
          }}
          ownPriceId={form.getFieldValue('ownPriceId')}
        />
      );
      break;
    case 'vatRateId':
      inputNode = <VatRateInput name="vatRateId" required />;
      break;
    case 'outNameId':
      inputNode = record.invoice.type === InvoiceType.Received && (
        <ServiceNameInput name="outNameId" required />
      );
      break;
  }

  return <td {...restProps}>{isEditMode && inputNode ? inputNode : children}</td>;
};
