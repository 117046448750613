import React from 'react';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useGetFlightProgram } from 'queries/flightProgram';
import { ProgramDetails } from './ProgramDetails';
import { ProgramServices } from './ProgramServices';
import { ProgramOrders } from './ProgramOrders';
import { NearestOrderDetails } from './NearestOrderDetails';

export const ProgramDrawerContent = () => {
  const { openProgramId } = useFlightProgramStore();

  const { isLoading } = useGetFlightProgram(openProgramId);

  return (
    <>
      <ProgramDetails />
      <NearestOrderDetails />
      <ProgramServices isLoading={isLoading} />
      {openProgramId && <ProgramOrders programId={openProgramId} />}
    </>
  );
};
