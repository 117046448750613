import React, { useContext, useEffect } from 'react';
import { Form, Row, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { GHOrderInputWithDayJs } from './utils/ghOrderUtils';
import { getSelectOptions, getUTCDate } from 'helpers';
import { orderStatuses } from 'consts/order';
import { Flight, OrderStatus, OrderType } from 'services/api/data-contracts';
import { useFormUpdate } from '../helpers/useFormUpdate';
import { usePermitFormUpdate } from '../helpers/usePermitFormUpdate';
import { useOrderStore } from 'store/orderStore';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form';
import { useSelectAutoFocus } from 'helpers';
import { FormContext } from '../../components/OrderDrawer/OrderDrawer';
import {
  GeneralInfoBlock,
  LegsBlock,
  OrderDetailsBlock,
  PermitDetailsBlock
} from '../../components/OrderDrawer/OrderDetails/accordions';

const availableOrderTypes = [OrderType.FUEL, OrderType.PMT, OrderType.ADD, OrderType.GH];

export const NewOrderView = () => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance;
  const flightIds = (useWatch('flights', form) || []).map((flight: Flight) => flight.id);

  const { current, setCurrent } = useOrderStore(({ current, setCurrent }) => ({
    current,
    setCurrent
  }));

  const ref = useSelectAutoFocus();

  useEffect(() => {
    if (current) {
      form.setFieldsValue(current);
    }
  }, []);

  const handleUpdateForm = useFormUpdate<GHOrderInputWithDayJs>(form, current?.type);
  const handleUpdatePermitForm = usePermitFormUpdate(form, current?.type);

  // Fields autocomplete based on the source flight
  const handleChangeType = (type: OrderType) => {
    if (current?.sourceFlight) {
      const { sourceFlight: flight } = current;
      let startDate = flight.actualTimeArrival || flight.estimatedTimeArrival;

      if (type === OrderType.PMT) {
        startDate = flight.estimatedTimeDeparture;

        const location = flight.arrivalAirport?.city?.country;
        setCurrent({ ...current, type, location });

        form.setFieldsValue({ locationId: location?.id });
      } else if (type === OrderType.ADD) {
        form.setFieldsValue({ locationTypeAndId: `airport#${flight.arrivalAirportId}` });
      } else {
        form.setFieldsValue({ locationId: flight.arrivalAirportId });
      }

      if (type !== OrderType.PMT) {
        const location = flight.arrivalAirport;
        setCurrent({ ...current, type, location });
      }

      form.setFieldValue('startEndDate', [getUTCDate(startDate), null]);
    } else {
      setCurrent({ ...current, type });
    }
  };

  return (
    <Form
      layout="vertical"
      requiredMark="optional"
      form={form}
      autoComplete="off"
      colon={false}
      onValuesChange={current?.type === OrderType.PMT ? handleUpdatePermitForm : handleUpdateForm}
      initialValues={{
        startEndDate: [null, null],
        flightIds: [],
        version: 0,
        status: OrderStatus.ESTD,
        isAlternateAirport: false
      }}
      className="styled-form">
      <Row style={{ marginTop: '12px' }}>
        {/* Hidden field for fields autocomplete after order type change when order is created from the flight */}
        <Form.Item name="sourceFlight" hidden />

        <Form.Item
          name="type"
          style={{ width: '128px' }}
          className="mr"
          rules={[{ required: true, message: 'Type is required' }]}>
          <Select
            options={getSelectOptions(availableOrderTypes)}
            size={CONTROL_SIZE}
            placeholder="Order type"
            onChange={handleChangeType}
            ref={ref}
          />
        </Form.Item>

        <Form.Item name="status" style={{ width: '120px' }}>
          <Select
            options={getSelectOptions(orderStatuses)}
            size={CONTROL_SIZE}
            placeholder="Status"
          />
        </Form.Item>
      </Row>

      <GeneralInfoBlock orderType={current?.type} />

      {current?.type === OrderType.PMT && (
        <>
          <PermitDetailsBlock />
          <LegsBlock isLoading={false} selectedFlights={flightIds || []} />
        </>
      )}

      {[OrderType.ADD, OrderType.GH, OrderType.FUEL].includes(current?.type as OrderType) && (
        <OrderDetailsBlock orderType={current?.type as OrderType} />
      )}
    </Form>
  );
};
