/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Aircraft,
  AircraftInput,
  AircraftListResponse,
  Error,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Aircrafts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of aircrafts
   *
   * @tags aircrafts
   * @name GetAircrafts
   * @summary Get a list of aircrafts
   * @request GET:/aircrafts
   * @secure
   */
  getAircrafts = (
    query?: {
      /** Filter by operatorId */
      operatorId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<AircraftListResponse, Error>({
      path: `/aircrafts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new aircraft
   *
   * @tags aircrafts
   * @name AddAircraft
   * @summary Create a new aircraft
   * @request POST:/aircrafts
   * @secure
   */
  addAircraft = (data: AircraftInput, params: RequestParams = {}) =>
    this.http.request<Aircraft, ValidationError | Error>({
      path: `/aircrafts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific aircraft
   *
   * @tags aircrafts
   * @name GetAircraftById
   * @summary Get a specific aircraft
   * @request GET:/aircrafts/{aircraftIdPathParam}
   * @secure
   */
  getAircraftById = (aircraftIdPathParam: number, params: RequestParams = {}) =>
    this.http.request<Aircraft, Error>({
      path: `/aircrafts/${aircraftIdPathParam}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific aircraft
   *
   * @tags aircrafts
   * @name UpdateAircraft
   * @summary Update a specific aircraft
   * @request PUT:/aircrafts/{aircraftIdPathParam}
   * @secure
   */
  updateAircraft = (aircraftIdPathParam: number, data: AircraftInput, params: RequestParams = {}) =>
    this.http.request<Aircraft, ValidationError | Error>({
      path: `/aircrafts/${aircraftIdPathParam}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific aircraft
   *
   * @tags aircrafts
   * @name DeleteAircraft
   * @summary Delete a specific aircraft
   * @request DELETE:/aircrafts/{aircraftIdPathParam}
   * @secure
   */
  deleteAircraft = (aircraftIdPathParam: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/aircrafts/${aircraftIdPathParam}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
