import React, { useRef } from 'react';
import { ConfigProvider, Empty, Flex, List, Radio } from 'antd';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CONTROL_SIZE } from 'consts/common';
import { Footer } from '../components/Footer';
import { useDashboardStore } from 'store/dashboardStore';
import { OrdersView } from './views/OrdersView/OrdersView';
import { ServicesView } from './views/ServicesView/ServicesView';
import { HeaderWithRef } from '../components/Header';
import { useMessageStore } from 'store/messageStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersWidgetMode, useOrdersDashboardStore, views } from 'store/ordersDashboardStore';
import { useOrderDrawerRouting, useFlightProgramDrawerRouting } from 'routing';
import { ProgramsView } from './views/ProgramsView/ProgramsView';
import { useFlightProgramStore } from 'store/flightProgramStore';

export const StyledList = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  height: 100%;

  .ant-spin-nested-loading {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .ant-spin-container.ant-spin-blur > div {
    min-height: 0 !important;
  }

  .ant-list-header {
    padding: 0 12px 8px !important;
  }

  .ant-list-footer {
    background-color: #fafafa;
    border-top: 1px solid #d9d9d9;
    z-index: 100;
    padding: 8px 16px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DispatcherOrdersWidget = () => {
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({ selectedMessages }));
  const { selectedFlights } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlights
  }));
  const {
    ordersList,
    servicesListItemsCount,
    selectedOrders,
    setSelectedOrders,
    ordersListItemsCount,
    widgetMode: currentView,
    setWidgetMode
  } = useOrdersDashboardStore();
  const { isFilterByFlights, isFilterByMessages } = useDashboardStore(({ linkFilters }) => ({
    isFilterByFlights: linkFilters.includes('flights-orders') && selectedFlights.length > 0,
    isFilterByMessages: linkFilters.includes('messages-orders') && selectedMessages.length > 0
  }));
  const { programsListItemsCount } = useFlightProgramStore(({ list }) => ({
    programsListItemsCount: list.length
  }));

  useOrderDrawerRouting();
  useFlightProgramDrawerRouting();

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    setSelectedOrders(checked ? ordersList : []);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);
  const headerHeight = headerRef.current?.clientHeight ? headerRef.current.clientHeight + 19 : 0;

  const header = (
    <HeaderWithRef
      showFlightTags={isFilterByFlights}
      showMessageTags={isFilterByMessages}
      total={ordersListItemsCount}
      ref={headerRef}
      slot={
        <Flex align="center" justify="flex-end" style={{ marginTop: '8px' }}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            defaultValue={currentView}
            onChange={(e) => setWidgetMode(e.target.value)}
            size={CONTROL_SIZE}>
            {views.map((view) => (
              <Radio.Button key={view} value={view} id={`order-widget-${view}-view-selector`}>
                {view}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Flex>
      }
    />
  );

  const footer = (
    <Footer
      selectedItemsCount={selectedOrders.length}
      totalItemsCount={
        currentView === 'Orders'
          ? ordersListItemsCount
          : currentView === 'Programs'
            ? programsListItemsCount
            : servicesListItemsCount
      }
      onSelectAll={handleSelectAll}
      widgetType="orders"
      showSelected={currentView === 'Orders'}
    />
  );

  const emptyListPlaceholder = `${currentView} not found`;

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description={emptyListPlaceholder} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      {currentView === OrdersWidgetMode.Orders ? (
        <OrdersView header={header} footer={footer} headerHeight={headerHeight} />
      ) : currentView === OrdersWidgetMode.Programs ? (
        <ProgramsView header={header} footer={footer} headerHeight={headerHeight} />
      ) : (
        <ServicesView header={header} footer={footer} />
      )}
    </ConfigProvider>
  );
};
