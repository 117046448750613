import { Flight, OrderStatus } from 'services/api/data-contracts';
import { TypedOrder } from 'store/orderStore';

export const getOrderFromFlight = (
  flight: Flight
): Partial<TypedOrder & { sourceFlight: Flight }> => ({
  status: OrderStatus.PRCG,
  operatorId: flight.operatorId,
  aircraftId: flight.aircraftId,
  arrivalFlightId: flight.id,
  flightIds: [flight.id],
  sourceFlight: flight,
  version: 0,
  customerId: undefined,
  payerId: undefined,
  type: undefined,
  isAlternateAirport: false,
  number: undefined
});
