import { Skeleton } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { AuditOutlined } from '@ant-design/icons';
import { cyan } from '@ant-design/colors';
import { capitalize } from 'lodash';
import { getUTCDate } from 'helpers';
import { ArrowIcon } from '../../ArrowIcon/ArrowIcon';
import { InvoiceType } from 'services/api/data-contracts';
import React from 'react';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';

const TitleWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Divider = styled('span')`
  margin: 0 8px;
`;

export const InvoiceDrawerTitle = () => {
  const { current, isInvoiceEditMode } = useInvoiceStore(({ current, isInvoiceEditMode }) => ({
    current,
    isInvoiceEditMode
  }));

  if (!current || (!isInvoiceEditMode && !current.invoiceNumber)) {
    return (
      <div data-testid="skeleton">
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />
      </div>
    );
  }

  if (isInvoiceEditMode) {
    return (
      <TitleWrapper>
        <AuditOutlined style={{ color: cyan[5], height: '20px', width: '20px' }} />
        <span>
          {`${capitalize(current?.type)} invoice ${current?.id ? 'editing' : 'creation'}`}
        </span>
      </TitleWrapper>
    );
  }

  const invoiceData = [
    `Invoice ${current?.invoiceNumber}`,
    current?.isPosted && 'Posted',
    getUTCDate(current?.invoiceDate).format(DATE_FORMAT),
    current?.isDisputed && 'Disputed'
  ].filter((prop) => !!prop);

  return (
    <TitleWrapper>
      <ArrowIcon type={current?.type as InvoiceType} />
      <span>
        {invoiceData.map((i, idx) => (
          <span key={i?.toString()}>
            {i}
            {idx !== invoiceData.length - 1 && <Divider>|</Divider>}
          </span>
        ))}
      </span>
    </TitleWrapper>
  );
};
