import { Skeleton } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { splitCamelCase, getUTCDate } from 'helpers';
import { MdAttachMoney } from 'react-icons/md';
import { purple } from '@ant-design/colors';
import { ArrowIcon } from './ArrowIcon/ArrowIcon';
import { ContractPaymentType, PaymentFullyHydrated } from 'services/api/data-contracts';
import React from 'react';
import styled from 'styled-components';
import { usePaymentStore } from 'store/paymentStore';

const TitleWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Divider = styled('span')`
  margin: 0 8px;
`;

export const PaymentDrawerTitle = ({ isLoading }: { isLoading?: boolean }) => {
  const { current, isPaymentEditMode } = usePaymentStore(({ current, isPaymentEditMode }) => ({
    current: current as PaymentFullyHydrated,
    isPaymentEditMode
  }));

  if (!current || (!current.type && !isPaymentEditMode) || isLoading) {
    return (
      <div data-testid="skeleton">
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />
      </div>
    );
  }

  const entityName = splitCamelCase(current.transactionType);

  if (isPaymentEditMode) {
    return (
      <TitleWrapper>
        <MdAttachMoney style={{ color: purple[4], height: '20px', width: '20px' }} />
        <span>
          {entityName} {current?.id ? 'editing' : 'creation'}
        </span>
      </TitleWrapper>
    );
  }

  const paymentData = [
    `${entityName} ${current.id}`,
    current.status,
    getUTCDate(current.date).format(DATE_FORMAT),
    current?.posted && 'Posted'
  ].filter((prop) => !!prop);

  return (
    <TitleWrapper>
      <ArrowIcon type={current.type as ContractPaymentType} />
      <span>
        {paymentData.map((i, idx) => (
          <span key={i?.toString()}>
            {i}
            {idx !== paymentData.length - 1 && <Divider>|</Divider>}
          </span>
        ))}
      </span>
    </TitleWrapper>
  );
};
