import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { InvoiceItemHydrated } from 'services/api/data-contracts';
import { CalculatorOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { getAmountValue } from 'helpers';
import { CONTROL_SIZE } from 'consts/common';

export const getInvoiceItemsColumns = (
  onOpenDetails: (item: InvoiceItemHydrated) => void,
  isIssuedInvoice?: boolean
): ColumnsType<InvoiceItemHydrated> => [
  {
    title: 'O',
    dataIndex: 'isOwnPrice',
    key: 'isOwnPrice',
    render: (_, record) =>
      record.isOwnPrice ? (
        <Tooltip title="Own price">
          <CheckOutlined />
        </Tooltip>
      ) : null,
    width: 22
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    render: (_, record) => record.serviceName?.name,
    ellipsis: true
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => record.orderService?.service.name,
    ellipsis: true,
    width: 94
  },
  {
    title: 'UOM',
    dataIndex: 'UOM',
    key: 'UOM',
    render: (_, record) => record.unitOfMeasure.shortName,
    width: 66,
    ellipsis: true
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value) => <Flex justify="end">{getAmountValue(value, 4)}</Flex>,
    width: 94,
    ellipsis: true
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => <Flex justify="end">{getAmountValue(value)}</Flex>,
    width: 80
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (value) => <Flex justify="end">{getAmountValue(value)}</Flex>,
    ellipsis: true,
    width: 80
  },
  {
    title: 'VAT, %',
    dataIndex: 'vatRate',
    key: 'vatRate',
    render: (_, record) => <Flex justify="end">{getAmountValue(record.vatRate?.rate)}</Flex>,
    width: 60
  },
  {
    title: 'Out name',
    dataIndex: 'outName',
    key: 'outName',
    render: (_, record) => record.outName?.name,
    ellipsis: true
  },
  {
    title: 'VAT, Amount',
    dataIndex: 'vatAmount',
    key: 'vatAmount',
    render: (value) => <Flex justify="end">{getAmountValue(value)}</Flex>,
    ellipsis: true,
    width: 80
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value, record) => {
      const isButtonVisible = !record.isOwnPrice || !isIssuedInvoice || !!record.ownPriceId;

      return (
        <Flex align="center" justify="end" style={{ overflow: 'hidden' }}>
          <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {getAmountValue(value)}
          </span>
          <div id="actions">
            {isButtonVisible && (
              <Tooltip title="Open calculation details">
                <Button
                  size={CONTROL_SIZE}
                  icon={<CalculatorOutlined />}
                  shape="circle"
                  onClick={() => onOpenDetails(record)}
                />
              </Tooltip>
            )}
          </div>
        </Flex>
      );
    },
    width: 80
  }
];
