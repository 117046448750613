import { create } from 'zustand';
import {
  Invoice,
  InvoiceHydrated,
  InvoiceItemHydratedWithRolledUp,
  LinksResponse,
  OrderService,
  PaymentHydrated,
  SettlementOrder
} from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

export type InvoiceHydratedWithAdditionalData = InvoiceHydrated & {
  isSomeServiceSelected?: boolean;
  orders?: SettlementOrder[];
};

type InvoiceStore = {
  current?: InvoiceHydratedWithAdditionalData;
  setCurrent: (current?: InvoiceHydratedWithAdditionalData) => void;
  services: OrderService[];
  setServices: (services: OrderService[]) => void;
  list: InvoiceHydrated[];
  setList: (list: InvoiceHydrated[]) => void;
  selectedInvoices: Invoice[];
  setSelectedInvoices: (selectedInvoices: Invoice[]) => void;
  isInvoiceEditMode: boolean;
  setInvoiceEditMode: (isInvoiceEditMode: boolean) => void;
  isInvoiceItemsEditMode: boolean;
  setInvoiceItemsEditMode: (isInvoiceItemsEditMode: boolean) => void;
  currentCreditNote?: Partial<PaymentHydrated>;
  setCurrentCreditNote: (creditNote?: Partial<PaymentHydrated>) => void;
  currentInvoiceItem?: InvoiceItemHydratedWithRolledUp;
  setCurrentInvoiceItem: (currentInvoiceItem?: InvoiceItemHydratedWithRolledUp) => void;
  editingInvoiceItemId?: number;
  setEditingInvoiceItemId: (id?: number) => void;
  links: LinksResponse;
  setLinks: (links: LinksResponse) => void;
  linkableInvoices: Invoice[];
  setLinkableInvoices: (linkableInvoices: Invoice[]) => void;
};

export const useInvoiceStore = create<InvoiceStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    services: [],
    setServices: (services) => {
      set({ services });
    },
    list: [],
    setList: (list) => {
      set({ list });
    },
    selectedInvoices: [],
    setSelectedInvoices: (selectedInvoices) => {
      set({ selectedInvoices });
    },
    isInvoiceEditMode: false,
    setInvoiceEditMode: (isInvoiceEditMode) => {
      set({ isInvoiceEditMode });
    },
    isInvoiceItemsEditMode: false,
    setInvoiceItemsEditMode: (isInvoiceItemsEditMode) => {
      set({ isInvoiceItemsEditMode });
    },
    currentCreditNote: undefined,
    setCurrentCreditNote: (currentCreditNote) => {
      set({ currentCreditNote });
    },
    currentInvoiceItem: undefined,
    setCurrentInvoiceItem: (currentInvoiceItem) => {
      set({ currentInvoiceItem });
    },
    editingInvoiceItemId: undefined,
    setEditingInvoiceItemId: (editingInvoiceItemId) => {
      set({ editingInvoiceItemId });
    },
    links: {
      messages: []
    },
    setLinks: (links) => {
      set({ links });
    },
    linkableInvoices: [],
    setLinkableInvoices: (linkableInvoices) => {
      set({ linkableInvoices });
    }
  }))
);
