import React from 'react';
import { Space } from 'antd';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceType } from 'services/api/data-contracts';
import { ExportPopover } from './ExportPopover';
import { ButtonWithConfirm } from './ButtonWithConfirm';
import { CancelButtonWithConfirm, CloseButton, EditButton } from 'components/Drawer';
import { OpenBalanceButton } from './OpenBalanceButton';
import { useMessageStore } from 'store/messageStore';
import { useCloseOnEsc, useCtrlEnterShortcut, useCtrlSShortcut } from 'helpers';
import { useDrawerStore } from 'store/drawerStore';
import { PdfPopoverErrorBoundary } from '../../../../components/ExportPdfPopover/PdfPopoverErrorBoundary';

interface IProps {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isWarning?: boolean;
  isFormTouched?: boolean;
}

export const HeaderButtons = ({
  isLoading,
  onSubmit,
  onClose,
  isWarning,
  isFormTouched
}: IProps) => {
  const { isInvoiceEditMode, setInvoiceEditMode, current } = useInvoiceStore(
    ({ isInvoiceEditMode, setInvoiceEditMode, current }) => ({
      isInvoiceEditMode,
      setInvoiceEditMode,
      current
    })
  );
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));
  const { isPaymentDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isPaymentDrawerOpen: openDrawers.includes('payment')
  }));

  useCtrlEnterShortcut(() => handleSubmit(true), !newMessageMode);
  useCtrlSShortcut(() => handleSubmit(false), !newMessageMode);
  useCloseOnEsc(onClose, (!isInvoiceEditMode || !isFormTouched) && !isPaymentDrawerOpen);

  const handleSubmit = async (shouldClose?: boolean) => {
    try {
      await onSubmit();
      shouldClose && handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setInvoiceEditMode(false);
    onClose();
  };

  if (isInvoiceEditMode) {
    return (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={handleClose}
          isLoading={isLoading}
          isFormTouched={isFormTouched}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(false)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={current?.id ? 'Save' : 'Create'}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(true)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={`${current?.id ? 'Save' : 'Create'} and close`}
          isPrimaryButton
        />
      </Space>
    );
  }

  return (
    <Space>
      {current?.type === InvoiceType.Issued && (
        <PdfPopoverErrorBoundary>
          <ExportPopover isLoading={isLoading} invoiceId={current.id} />
        </PdfPopoverErrorBoundary>
      )}

      <OpenBalanceButton isLoading={isLoading} />

      <EditButton
        onClick={() => setInvoiceEditMode(true)}
        isLoading={isLoading}
        dataTestId={'edit-invoice-btn'}
      />

      <CloseButton onClick={onClose} />
    </Space>
  );
};
