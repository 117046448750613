/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  RebateHydrated,
  RebateInput,
  RebateListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Rebates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of Rebate records
   *
   * @tags rebates
   * @name GetAllRebates
   * @summary Get a list of Rebate records
   * @request GET:/rebates
   * @secure
   */
  getAllRebates = (
    query?: {
      /**
       * Filter by match (or partial match) of rebate name, or rebate customer name, or rebate service name
       * @example "fuel"
       */
      name?: string;
      /** Filter by type - extra or discount */
      type?: 'extra' | 'discount';
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<RebateListResponse, Error>({
      path: `/rebates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new Rebate record
   *
   * @tags rebates
   * @name AddRebate
   * @summary Create a new Rebate record
   * @request POST:/rebates
   * @secure
   */
  addRebate = (data: RebateInput, params: RequestParams = {}) =>
    this.http.request<RebateHydrated, ValidationError | Error>({
      path: `/rebates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific Rebate record by id
   *
   * @tags rebates
   * @name GetRebateById
   * @summary Get a specific Rebate record
   * @request GET:/rebates/{rebateId}
   * @secure
   */
  getRebateById = (rebateId: number, params: RequestParams = {}) =>
    this.http.request<RebateHydrated, Error>({
      path: `/rebates/${rebateId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific Rebate document by id
   *
   * @tags rebates
   * @name UpdateRebate
   * @summary Update a specific Rebate document
   * @request PUT:/rebates/{rebateId}
   * @secure
   */
  updateRebate = (rebateId: number, data: RebateInput, params: RequestParams = {}) =>
    this.http.request<RebateHydrated, ValidationError | Error>({
      path: `/rebates/${rebateId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific Rebate record by id
   *
   * @tags rebates
   * @name DeleteRebate
   * @summary Delete a specific Rebate record
   * @request DELETE:/rebates/{rebateId}
   * @secure
   */
  deleteRebate = (rebateId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/rebates/${rebateId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
