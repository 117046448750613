import { ColumnsType } from 'antd/es/table';
import { InvoiceItemHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { DATE_FORMAT } from 'consts/common';

export const getRolledUpInvoiceItemsColumns = (): ColumnsType<InvoiceItemHydrated> => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) => getUTCDate(value).format(DATE_FORMAT)
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    render: (_, record) => record.serviceName?.name,
    ellipsis: true
  },
  {
    title: 'UOM',
    dataIndex: 'UOM',
    key: 'UOM',
    render: (_, record) => record.unitOfMeasure?.shortName,
    width: 66,
    ellipsis: true
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value) => getAmountValue(value, 2),
    width: 94,
    ellipsis: true
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency',
    width: 45,
    ellipsis: true
  },
  {
    title: 'Total amount',
    dataIndex: 'total',
    key: 'total',
    align: 'end',
    render: (value) => getAmountValue(value),
    width: 120
  }
];
