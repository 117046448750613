import React from 'react';
import { Flex, Switch, Typography } from 'antd';
import { Footer } from '../../../components/Footer';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMessageStore } from 'store/messageStore';
import { LIST_FOOTER_BULK_BLOCK_HEIGHT } from '../../../../consts';
import styled from 'styled-components';
import { DoneButton } from './DoneButton';
import { LinkButton } from './LinkButton';
import { filter, map } from 'lodash';
import { CONTROL_SIZE } from 'consts/common';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { LinkAndDoneButton } from './LinkAndDoneButton';

const StyledFlex = styled(Flex)`
  background-color: white;
  box-shadow: 0 2px 8px 0 #00000026;
  padding: 8px;
  border-radius: 4px;
`;

export const MessagesListFooter = () => {
  const {
    messages,
    selectedMessages,
    setSelectedMessages,
    messagesListItemsCount,
    messagesFilter,
    setMessagesFilter
  } = useMessageStore(
    ({
      list,
      selectedMessages,
      setSelectedMessages,
      messagesListItemsCount,
      messagesFilter,
      setMessagesFilter
    }) => ({
      messages: list,
      selectedMessages,
      setSelectedMessages,
      messagesListItemsCount,
      messagesFilter,
      setMessagesFilter
    })
  );

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    const selectedMessages = map(filter(messages, 'id'), ({ id, subject }) => ({
      id,
      subject: subject?.toString() || ''
    }));

    setSelectedMessages(checked ? selectedMessages : []);
  };

  const handleChangeDoneFilter: SwitchChangeEventHandler = (checked) => {
    setMessagesFilter({ ...messagesFilter, showDone: checked });
  };

  return (
    <Flex vertical gap={16}>
      {selectedMessages.length >= 1 && (
        <StyledFlex
          style={{ height: LIST_FOOTER_BULK_BLOCK_HEIGHT - 16 }}
          align="center"
          justify="flex-start"
          gap={8}>
          <LinkButton />
          <LinkAndDoneButton />
          <DoneButton />
        </StyledFlex>
      )}

      <Footer
        showSelected
        selectedItemsCount={selectedMessages.length}
        totalItemsCount={messagesListItemsCount}
        onSelectAll={handleSelectAll}
        widgetType="messages"
        showClearSelection={selectedMessages.length >= 1}>
        <>
          <Switch
            size={CONTROL_SIZE}
            value={messagesFilter?.showDone}
            style={{ bottom: 2 }}
            onChange={handleChangeDoneFilter}
          />
          <Typography.Text style={{ marginLeft: 8 }}>
            ‘Done’ is {messagesFilter?.showDone ? 'included' : 'hidden'}
          </Typography.Text>
        </>
      </Footer>
    </Flex>
  );
};
