import React, { ReactNode, useContext } from 'react';
import { Button, Checkbox, Flex, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  CloseSquareOutlined,
  MailOutlined,
  ProfileOutlined,
  SendOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { useDashboardStore } from 'store/dashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { orderTypes } from 'consts/order';
import { DashboardContext, DashboardType } from '../../consts';
import { CONTROL_SIZE, DEFAULT_LIMIT } from 'consts/common';
import { blue } from '@ant-design/colors';

const StyledFlex = styled(Flex)`
  span:has(span.label) {
    padding-inline-start: 6px;
    padding-inline-end: 0;
  }
`;

const ContainerWithBorderLeft = styled.div`
  display: inline;
  border-left: 1px solid #0000000f;
  padding-left: 12px;
`;

export const entityTypes = ['messages', 'orders', 'flights'];
export type EntityType = (typeof entityTypes)[number];

const iconsMap: Record<EntityType, ReactNode> = {
  messages: <MailOutlined />,
  flights: <SendOutlined />,
  orders: <ProfileOutlined />
};

interface IProps {
  totalItemsCount: number;
  selectedItemsCount: number;
  showClearSelection?: boolean;
  onSelectAll: (e: CheckboxChangeEvent) => void;
  widgetType: EntityType;
  showSelected?: boolean;
  children?: React.ReactNode;
}

export const Footer = ({
  totalItemsCount,
  selectedItemsCount,
  onSelectAll,
  widgetType,
  showSelected,
  children,
  showClearSelection
}: IProps) => {
  const { linkFilters, setLinkFilters } = useDashboardStore(({ linkFilters, setLinkFilters }) => ({
    linkFilters,
    setLinkFilters
  }));
  const { setFlightsFilter, setFlightsPageNumber } = useFlightsDashboardStore(
    ({ setFlightsFilter, setFlightsPageNumber, selectedFlights }) => ({
      setFlightsFilter,
      setFlightsPageNumber,
      selectedFlights
    })
  );
  const { setOrdersFilter } = useOrdersDashboardStore(({ setOrdersFilter, selectedOrders }) => ({
    setOrdersFilter,
    selectedOrders
  }));

  const { type: dashboardType } = useContext(DashboardContext);

  const getFilterTargets = (): EntityType[] | undefined => {
    switch (widgetType) {
      case 'flights':
        return ['messages', 'orders'];
      case 'messages':
        return [...(dashboardType === DashboardType.Dispatcher ? ['flights'] : []), 'orders'];
      case 'orders':
        return ['messages', ...(dashboardType === DashboardType.Dispatcher ? ['flights'] : [])];
    }
  };

  const renderSelectAllCheckbox = () => (
    <Checkbox
      style={{ marginRight: '8px' }}
      onChange={onSelectAll}
      indeterminate={!!selectedItemsCount && selectedItemsCount !== totalItemsCount}
      checked={selectedItemsCount === totalItemsCount}
      id={`select-all-${widgetType}`}
      data-testid={`select-all-${widgetType}`}
    />
  );

  const renderClearSelectionButton = () => (
    <Tooltip title="Reset selected">
      <Button
        size={CONTROL_SIZE}
        type="text"
        icon={<CloseSquareOutlined style={{ color: blue[5] }} />}
        onClick={() => onSelectAll({ target: { checked: false } } as CheckboxChangeEvent)}
        style={{ marginRight: '4px' }}
        id={`clear-all-${widgetType}`}
        data-testid={`clear-all-${widgetType}`}
      />
    </Tooltip>
  );

  const renderFilterCheckbox = (filterTarget: EntityType) => {
    const checkboxId = `${widgetType}-${filterTarget}`;

    const onChange = ({ target }: CheckboxChangeEvent) => {
      const filterType = target.id || '';
      const filterValue = target.checked;

      setLinkFilters(
        filterValue ? [...linkFilters, filterType] : linkFilters.filter((i) => i !== filterType)
      );

      if (filterTarget === 'flights' && filterValue) {
        setFlightsFilter({});
        setFlightsPageNumber(1);
      } else if (filterTarget === 'orders' && filterValue) {
        setOrdersFilter({ orderTypes: orderTypes });
      }
    };

    return (
      <Checkbox
        key={checkboxId}
        id={checkboxId}
        data-testid={checkboxId}
        checked={linkFilters.includes(checkboxId)}
        disabled={dashboardType === DashboardType.Settlement}
        onChange={onChange}>
        <span className="label">{iconsMap[filterTarget]}</span>
      </Checkbox>
    );
  };

  const showCounter = showSelected && !!totalItemsCount;

  return (
    <Flex align="center" justify="space-between">
      <div style={{ lineHeight: '24px' }}>
        {showCounter && (
          <div style={{ minWidth: 110, display: 'inline-block' }}>
            {totalItemsCount <= 15
              ? renderSelectAllCheckbox()
              : showClearSelection && renderClearSelectionButton()}
            {selectedItemsCount} out of {totalItemsCount}
            {widgetType === 'orders' && totalItemsCount === DEFAULT_LIMIT && '+'}
          </div>
        )}
        {children && (
          <ContainerWithBorderLeft style={{ marginLeft: showCounter ? 12 : 118 }}>
            {children}
          </ContainerWithBorderLeft>
        )}
      </div>

      <StyledFlex gap={12}>
        {!!totalItemsCount && showSelected ? (
          <>
            {widgetType !== 'flights' && <span style={{ color: '#00000073' }}>Filter:</span>}
            {getFilterTargets()?.map(renderFilterCheckbox)}
          </>
        ) : (
          <br />
        )}
      </StyledFlex>
    </Flex>
  );
};
