import React from 'react';
import { LinksResponse, SettlementInvoiceItem } from 'services/api/data-contracts';
import { Button, Flex, Table, Tooltip } from 'antd';
import { numericSorter } from '../../../Dictionaries/utils/tableSorters';
import { CONTROL_SIZE } from 'consts/common';
import { ArrowIcon } from '../../InvoicesWidget/components/ArrowIcon/ArrowIcon';
import { CheckOutlined, CloseOutlined, EditFilled, LinkOutlined } from '@ant-design/icons';
import { GoUnlink } from 'react-icons/go';
import { getAmountValue } from 'helpers';
import { MessageTag } from 'store/messageStore';
import { getLinkActionStatus } from './helpers/getLinkActionStatus';
import { LINK_STATUS } from './helpers/constants';
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface';
import styled from 'styled-components';
import { VscArrowSwap } from 'react-icons/vsc';

const CellWrapper = styled(Flex)<{ $isEditMode: boolean }>`
  margin-top: ${(p) => (p.$isEditMode ? 5 : 0)}px;
  transition: margin 500ms;
`;

type EditableColumnsType = (
  | (ColumnGroupType<SettlementInvoiceItem> & { isEditable?: boolean })
  | (ColumnType<SettlementInvoiceItem> & { isEditable?: boolean })
)[];

export const getColumns = (
  isFullScreenMode: boolean,
  onOpenInvoice: (id: number) => void,
  editingId: number | undefined,
  onSetEditMode: (invoiceItem?: SettlementInvoiceItem) => void,
  onUpdate: () => void,
  onLinkInvoice: (invoiceItem: SettlementInvoiceItem, status: string) => void,
  setInvoiceId: (id: number | null) => void,
  links: { data?: LinksResponse; isFetching: boolean },
  selectedMessages: MessageTag[]
): EditableColumnsType => [
  {
    title: 'Inv',
    dataIndex: 'invoiceId',
    key: 'invoiceId',
    width: 40,
    render: (_, record) => (
      <Tooltip
        title={
          editingId
            ? 'To open invoice please finish the invoice item update'
            : record.linkId
              ? 'Reissued'
              : `Open ${record.invoice.type.toLowerCase()} invoice`
        }>
        <Button
          type="link"
          size={CONTROL_SIZE}
          data-testid="open-invoice-btn"
          icon={
            record.linkId ? (
              <VscArrowSwap style={{ color: '#00000073' }} />
            ) : (
              <ArrowIcon type={record.invoice.type} hideTooltip />
            )
          }
          onClick={() => onOpenInvoice(record.invoiceId)}
          disabled={!!editingId}
          style={{ marginTop: editingId === record.id ? '6px' : 0, transition: 'margin 500ms' }}
        />
      </Tooltip>
    )
  },
  ...(isFullScreenMode
    ? [
        {
          title: 'O',
          dataIndex: 'isOwnPrice',
          key: 'isOwnPrice',
          render: (value: boolean, record: SettlementInvoiceItem) =>
            value ? (
              <Tooltip title="Own price">
                <CheckOutlined
                  style={{
                    marginTop: editingId === record.id ? '10px' : 0,
                    transition: 'margin 500ms'
                  }}
                />
              </Tooltip>
            ) : null,
          width: 27
        }
      ]
    : []),
  Table.EXPAND_COLUMN,
  {
    title: 'Item',
    dataIndex: 'serviceNameId',
    key: 'serviceNameId',
    ellipsis: true,
    render: (_, record) => (
      <>
        <Tooltip
          title={
            <>
              <div>{record.serviceName.name}</div>
              <div>{record.invoice.isPosted ? 'Posted' : 'Unposted'}</div>
            </>
          }>
          {record.serviceName.name}
        </Tooltip>
        {!!record.agentFee && record.agentFee > 0 && (
          <>
            <br />
            <span style={{ color: '#00000073' }}>Agent fee {record.agentFee}%</span>
          </>
        )}
      </>
    ),
    isEditable: true,
    width: 120
  },
  {
    title: 'Invoice №',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {record.invoice.invoiceNumber}
      </CellWrapper>
    ),
    width: 106
  },
  {
    title: 'UOM',
    dataIndex: 'unitOfMeasure',
    key: 'unitOfMeasure',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {record.unitOfMeasure.shortName}
      </CellWrapper>
    ),
    width: 60,
    isEditable: true
  },
  {
    title: isFullScreenMode ? 'Quantity' : 'Quant',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value) => <Flex justify="end">{getAmountValue(value, 4)}</Flex>,
    ellipsis: true,
    sorter: (a, b) => numericSorter(a.quantity, b.quantity),
    isEditable: true,
    width: 150
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {record.invoice.currency}
      </CellWrapper>
    ),
    width: 80
  },
  ...(isFullScreenMode
    ? [
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          render: (value: number, record: SettlementInvoiceItem) => (
            <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
              {getAmountValue(value)}
            </CellWrapper>
          ),
          width: 150,
          ellipsis: true,
          isEditable: true
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          render: (value: number, record: SettlementInvoiceItem) => (
            <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
              {getAmountValue(value)}
            </CellWrapper>
          ),
          width: 100
        },
        {
          title: 'VAT, %',
          dataIndex: 'vatRateId',
          key: 'vatRateId',
          render: (_: number, record: SettlementInvoiceItem) => (
            <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
              {getAmountValue(record.vatRate?.rate)}
            </CellWrapper>
          ),
          width: 120,
          isEditable: true
        },
        {
          title: 'Out name',
          dataIndex: 'outNameId',
          key: 'outNameId',
          render: (_: number, record: SettlementInvoiceItem) => (
            <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
              {record.outName?.name || '-'}
            </CellWrapper>
          ),
          width: 200,
          ellipsis: true,
          isEditable: true
        },
        {
          title: 'VAT, Amount',
          dataIndex: 'vatAmount',
          key: 'vatAmount',
          render: (value: number, record: SettlementInvoiceItem) => (
            <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
              {getAmountValue(value)}
            </CellWrapper>
          ),
          width: 110
        }
      ]
    : []),
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value, record) => {
      const status = getLinkActionStatus(links, selectedMessages);

      const buttons = [];

      if (isFullScreenMode && editingId === record.id) {
        buttons.push(
          <Tooltip title="Cancel" key="Cancel">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => onSetEditMode()}
            />
          </Tooltip>,
          <Tooltip title="Save" key="Save">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<CheckOutlined />}
              type="primary"
              onClick={onUpdate}
            />
          </Tooltip>
        );
      } else if (isFullScreenMode) {
        buttons.push(
          <Tooltip title="Edit" key="Edit">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<EditFilled />}
              type="primary"
              onClick={() => onSetEditMode(record)}
            />
          </Tooltip>
        );
      } else
        buttons.push(
          <Tooltip title={status} placement="left" fresh key="Link">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              data-testid="link-btn"
              icon={
                status === LINK_STATUS.LINK_INVOICE ? (
                  <LinkOutlined style={{ marginTop: 4 }} />
                ) : (
                  <GoUnlink style={{ marginTop: 4 }} />
                )
              }
              onClick={() => onLinkInvoice(record, status)}
              onMouseEnter={() => setInvoiceId(record.invoiceId)}
              onMouseLeave={() => setInvoiceId(null)}
              loading={links.isFetching}
            />
          </Tooltip>
        );

      return (
        <CellWrapper
          gap={8}
          style={{
            overflow: 'hidden',
            marginRight: 8
          }}
          align="center"
          justify="end"
          $isEditMode={editingId === record.id}>
          <div>
            {getAmountValue(value)}
            {record.agentFeeAmount &&
              record.agentFeeAmount > 0 &&
              !(isFullScreenMode && editingId) && (
                <>
                  <br />
                  <span style={{ float: 'inline-end', color: '#00000073' }}>
                    {getAmountValue(record.agentFeeAmount)}
                  </span>
                </>
              )}
          </div>
          <div id="actions">{buttons}</div>
        </CellWrapper>
      );
    },
    width: 142,
    sorter: (a, b) => numericSorter(a.amount, b.amount)
  }
];
