import React from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import {
  ContractPaymentType,
  Invoice,
  Payment,
  TransactionType
} from 'services/api/data-contracts';
import { CONTROL_SIZE, DATE_FORMAT_SHORT } from 'consts/common';
import { Button, Dropdown, Flex, MenuProps, Tooltip } from 'antd';
import { ArrowIcon as InvoiceArrowIcon } from '../../InvoicesWidget/components/ArrowIcon/ArrowIcon';
import { ArrowIcon as PaymentArrowIcon } from '../../components/PaymentDrawer/ArrowIcon/ArrowIcon';
import { InvoiceStatus } from '../../InvoicesWidget/components/InvoiceStatus/InvoiceStatus';
import { isInvoice } from './helpers';
import { CheckOutlined, MoreOutlined } from '@ant-design/icons';
import { getAmountValue, getUTCDate, splitCamelCase } from 'helpers';
import { green, red } from '@ant-design/colors';

export const getColumns = (
  onOpen: (payment: Payment & { balance?: number; advance: number; key: string }) => void,
  selectedPaymentId?: number
): ColumnsType<
  (Invoice & { balance?: number }) | (Payment & { balance?: number; advance: number; key: string })
> => [
  Table.SELECTION_COLUMN,
  {
    title: '',
    dataIndex: 'direction',
    key: 'direction',
    width: 25,
    render: (_, record) => (
      <Flex align="center" justify="center">
        {isInvoice(record) ? (
          <InvoiceArrowIcon type={(record as Invoice).type} />
        ) : (
          <PaymentArrowIcon type={(record as Payment).type as ContractPaymentType} />
        )}
      </Flex>
    )
  },
  {
    title: '',
    dataIndex: 'entityType',
    key: 'entityType',
    width: 60,
    render: (_, record) => {
      let fullEntityName: string;
      let shortEntityName: string;

      if (isInvoice(record)) {
        fullEntityName = 'Invoice';
        shortEntityName = 'Inv';
      } else {
        const payment = record as Payment;
        fullEntityName = splitCamelCase(payment.transactionType) || '';
        shortEntityName = payment.transactionType === TransactionType.CreditNote ? 'Cred' : 'Paym';
      }

      return (
        <Flex gap={12} align={'center'}>
          <Tooltip title={fullEntityName}>{shortEntityName}</Tooltip>
        </Flex>
      );
    }
  },
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    width: 25,
    render: (_, record) => {
      if (isInvoice(record)) {
        const invoice = record as Invoice;

        return (
          <Flex align="center" justify="center">
            <InvoiceStatus
              isExpired={getUTCDate().diff(invoice.dueDate) > 0}
              amountDue={invoice.amountDue}
              total={invoice.invoiceTotal}
            />
          </Flex>
        );
      }
    }
  },
  {
    title: 'Dis',
    dataIndex: 'isDisputed',
    key: 'isDisputed',
    render: (_, record) => {
      if (isInvoice(record) && (record as Invoice).isDisputed) {
        return <CheckOutlined />;
      }
    },
    width: 36
  },
  {
    title: '№',
    dataIndex: 'entityNumber',
    key: 'entityNumber',
    render: (_, record) => {
      const entityNumber = isInvoice(record)
        ? (record as Invoice).invoiceNumber
        : (record as Payment).creditNoteNumber || record.id;

      return <Tooltip title={entityNumber}>{entityNumber}</Tooltip>;
    },
    ellipsis: true,
    width: 72
  },
  {
    title: 'Date',
    dataIndex: 'entityDate',
    key: 'entityDate',
    render: (_, record) => {
      const entityDate = isInvoice(record)
        ? (record as Invoice).invoiceDate
        : (record as Payment).date;

      return entityDate ? getUTCDate(entityDate).format(DATE_FORMAT_SHORT) : '';
    },
    ellipsis: true,
    width: 72
  },
  {
    title: 'Invoice total',
    dataIndex: 'invoiceTotal',
    key: 'invoiceTotal',
    render: (_, record) =>
      isInvoice(record) ? getAmountValue((record as Invoice).invoiceTotal) : '',
    align: 'end',
    ellipsis: true,
    width: 90
  },
  {
    title: 'Payment total',
    dataIndex: 'amount',
    key: 'amount',
    render: (_, record) => (isInvoice(record) ? '' : getAmountValue((record as Payment).amount)),
    align: 'end',
    ellipsis: true,
    width: 90
  },
  {
    title: 'Amount due/Advance',
    dataIndex: 'amountDue',
    key: 'amountDue',
    render: (_, record) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {getAmountValue(
          isInvoice(record)
            ? (record as Invoice).amountDue
            : (record as Payment & { balance?: number; advance: number }).advance
        )}
      </span>
    ),
    ellipsis: true,
    align: 'end',
    width: 102
  },
  {
    title: 'Due date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (value) => value && getUTCDate(value).format(DATE_FORMAT_SHORT),
    ellipsis: true,
    width: 80
  },
  {
    title: 'Delayed',
    dataIndex: 'overdueDays',
    key: 'overdueDays',
    render: (value) =>
      value && (
        <span style={{ fontWeight: 500, color: value < 0 ? green[6] : red[5] }}>{value}</span>
      ),
    ellipsis: true,
    width: 68
  },
  {
    title: 'Flight',
    dataIndex: 'flight',
    key: 'flight',
    render: (value) => value || '',
    ellipsis: true,
    width: 70
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (value) => (value ? value : ''),
    ellipsis: true,
    width: 70
  },
  {
    title: 'Service',
    dataIndex: 'serviceDate',
    key: 'serviceDate',
    render: (value) => (value ? value : ''),
    ellipsis: true,
    width: 83
  },
  {
    title: 'Curr',
    dataIndex: 'currency',
    key: 'currency',
    width: 50
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    align: 'right',
    ellipsis: true,
    render: (value) => {
      if (!value) {
        return '';
      }

      let displayColor = '#000';
      let fontWeight = '400';

      if (value > 0) {
        displayColor = green[6];
        fontWeight = '600';
      } else if (value < 0) {
        displayColor = red[5];
        fontWeight = '600';
      }

      return (
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              color: displayColor,
              fontWeight: fontWeight,
              whiteSpace: 'nowrap',
              textAlign: 'right'
            }}>
            {value > 0 ? '+' : ''}
            {getAmountValue(value)}
          </span>
        </Tooltip>
      );
    },
    width: 106
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 50,
    ellipsis: true,
    render: (value, record) => {
      const dropdownMenuItems: MenuProps['items'] = [
        {
          key: '1-1',
          label: 'Link the selected',
          disabled: selectedPaymentId !== record.id,
          onClick: () => {
            onOpen(record as Payment & { balance?: number; advance: number; key: string });
          }
        },
        {
          key: '1-2',
          label: 'Open in new tab',
          disabled: true
        }
      ];

      return isInvoice(record) ? (
        value
      ) : (
        <div>
          <Tooltip title={value}>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {value}
            </span>
          </Tooltip>

          <Dropdown menu={{ items: dropdownMenuItems }} trigger={['click']}>
            <Button
              shape="circle"
              type="primary"
              icon={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
              className="float-btn"
              size={CONTROL_SIZE}
            />
          </Dropdown>
        </div>
      );
    }
  }
];
