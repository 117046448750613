import React, { useContext } from 'react';
import { Descriptions, DescriptionsProps, Flex } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DataContext } from '../DictionaryDetails';
import { getAmountValue } from 'helpers';
import { SpecialParametersView } from './components/SpecialParameters/SpecialParametersView';
import { RebateHydratedWithDayJs, RebateTypeMap, useRebateStore } from 'store/rebateStore';
import { RebateType } from 'services/api/data-contracts';

export const RebateDictionaryView = () => {
  const formData = useContext(DataContext) as unknown as RebateHydratedWithDayJs;

  const { current: data } = useRebateStore(({ current }) => ({
    current
  }));

  if (!data || !formData) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Type',
      children: data.type ? RebateTypeMap[data.type] : '-'
    },
    {
      label: 'Name',
      span: 2,
      children: data.name
    },
    {
      label: 'Service name',
      children: data.serviceName?.name,
      span: 2
    },
    ...(data.type && [RebateType.AgentFeeDiscount, RebateType.AgentFeeExtra].includes(data.type)
      ? [
          {
            label: 'Amount',
            children: getAmountValue(data.value) + '%'
          }
        ]
      : [
          {
            label: 'Currency',
            children: data.currency || '-'
          },
          {
            label: 'Amount',
            children: getAmountValue(data.value)
          },
          {
            label: 'UOM',
            span: 2,
            children: data.unitOfMeasure?.shortName || '-'
          }
        ]),
    {
      label: 'Start date',
      children: data.startDate ? data.startDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'End date',
      children: data.endDate ? data.endDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'Notes',
      span: 2,
      children: <span style={{ whiteSpace: 'pre-line' }}>{data.notes || '-'}</span>
    }
  ];

  return (
    <Flex vertical gap={28}>
      <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />
      {data.specialParameters && data.specialParameters.length > 0 && (
        <SpecialParametersView specialParameters={data.specialParameters} />
      )}
    </Flex>
  );
};
