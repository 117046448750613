import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TDrawer =
  | 'calculator'
  | 'creditNote'
  | 'flight'
  | 'flightProgram'
  | 'invoice'
  | 'order'
  | 'orderService'
  | 'payment'
  | 'paymentPurpose';

type DrawerStore = {
  openDrawers: TDrawer[];
  setDrawerOpen: (drawer: TDrawer) => void;
  setDrawerClosed: (drawer: TDrawer) => void;
  closeAllDrawers: () => void;
};

export const useDrawerStore = create<DrawerStore>()(
  devtools((set, getState) => ({
    openDrawers: [],
    setDrawerOpen: (drawer) => {
      set({ openDrawers: [...getState().openDrawers, drawer] });
    },
    setDrawerClosed: (drawer) => {
      set({ openDrawers: getState().openDrawers.filter((d) => d !== drawer) });
    },
    closeAllDrawers: () => {
      set({ openDrawers: [] });
    }
  }))
);
