import { MessageTag } from 'store/messageStore';
import { LinkActionTypes, LinksResponse } from 'services/api/data-contracts';
import { getLinkAction } from './getLinkAction';
import { LINK_STATUS } from './constants';

export const getLinkActionStatus = (
  links: { data?: LinksResponse; isFetching: boolean },
  selectedMessages: MessageTag[]
) => {
  if (selectedMessages?.length === 0) {
    return LINK_STATUS.SELECT_MESSAGE;
  }

  if (links.isFetching) {
    return LINK_STATUS.LOADING;
  }

  const linkedMessages = links.data?.messages || [];
  const action = getLinkAction(linkedMessages, selectedMessages);

  if (action === LinkActionTypes.Link) {
    return LINK_STATUS.LINK_INVOICE;
  }

  if (action === LinkActionTypes.Unlink) {
    return LINK_STATUS.UNLINK_INVOICE;
  }

  if (!action) {
    return LINK_STATUS.INVALID_LINKS_COMBINATION;
  }

  return LINK_STATUS.LINK_INVOICE;
};
