import React from 'react';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { CONTROL_SIZE } from 'consts/common';
import { CollapseProps } from 'antd/es/collapse';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { Flex } from 'antd';
import { PaymentFullyHydrated } from 'services/api/data-contracts';
import { PaymentPurposesView } from './PaymentPurposesView';
import { PaymentPurposeForm } from './PaymentPurposeForm';

interface IProps {
  payment: PaymentFullyHydrated;
}

export const PaymentPurposeBlock = ({ payment }: IProps) => {
  const { isCreationMode } = usePaymentPurposeStore(({ isCreationMode }) => ({
    isCreationMode
  }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      forceRender: true,
      label: (
        <Flex gap={4} align="center">
          Invoices
          <StyledBadge
            count={(payment.paymentPurposes || []).filter(Boolean).length}
            color={blue[0]}
          />
        </Flex>
      ),
      children: (
        <>
          {payment && <PaymentPurposesView />}
          {payment.posted && payment?.advance !== 0 && isCreationMode && <PaymentPurposeForm />}
        </>
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      ghost
      style={{ marginTop: '12px' }}
    />
  );
};
