import { LinkedEntityTypes } from 'services/api/data-contracts';
import { EntityType } from './Footer';

export const mapEntityTypeToLinkedEntityType = (
  entityType: EntityType
): LinkedEntityTypes | undefined => {
  switch (entityType) {
    case 'flights':
      return LinkedEntityTypes.Flight;
    case 'orders':
      return LinkedEntityTypes.Order;
    case 'messages':
      return LinkedEntityTypes.Message;
    case 'invoice':
      return LinkedEntityTypes.Invoice;
    default:
      return undefined;
  }
};
