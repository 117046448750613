import { useContext } from 'react';
import { DashboardContext, DashboardType } from '../../../consts';

export type LinkAction =
  | 'loading'
  | 'disabled'
  | 'link-selected'
  | 'unlink-selected'
  | 'unlink-all'
  | null;

interface ILinkActionProps {
  isDropdownOpen: boolean;
  isError: boolean;
  isFetching: boolean;
  linkedFlightIds: number[];
  linkedOrderNumbers: string[];
  linkedInvoiceIds: number[];
  selectedFlightIds: number[];
  selectedOrderNumbers: string[];
}

export const useLinkAction = ({
  isDropdownOpen,
  isError,
  isFetching,
  linkedFlightIds,
  linkedOrderNumbers,
  linkedInvoiceIds,
  selectedFlightIds,
  selectedOrderNumbers
}: ILinkActionProps): LinkAction => {
  const { type: dashboardType } = useContext(DashboardContext);

  const selectedEntities = [...selectedFlightIds, ...selectedOrderNumbers];
  const linkedEntities =
    dashboardType === DashboardType.Dispatcher
      ? [...linkedFlightIds, ...linkedOrderNumbers]
      : dashboardType === DashboardType.Settlement
        ? [...linkedInvoiceIds, ...linkedOrderNumbers]
        : [];

  if (!isDropdownOpen || isFetching) {
    return 'loading';
  }

  // Nothing to link/unlink
  if (isError || (!selectedEntities.length && !linkedEntities.length)) {
    return 'disabled';
  }

  // Something is selected AND all selected flights/orders are linked
  if (
    selectedEntities.length > 0 &&
    selectedFlightIds.every((id) => linkedFlightIds.includes(id)) &&
    selectedOrderNumbers.every((id) => linkedOrderNumbers.includes(id))
  ) {
    return 'unlink-selected';
  }

  // Something is selected AND all selected flights/orders are not linked
  if (
    selectedEntities.length > 0 &&
    selectedFlightIds.every((id) => !linkedFlightIds.includes(id)) &&
    selectedOrderNumbers.every((id) => !linkedOrderNumbers.includes(id))
  ) {
    return 'link-selected';
  }

  // Something is linked, but other cases are not valid
  if (linkedEntities.length > 0) {
    return 'unlink-all';
  }

  return null;
};
