import React, { useState } from 'react';
import { Select } from 'antd';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { useLocationStore } from 'store/locationStore';
import { useGetLocations } from 'queries/locations';
import { useDebounce } from 'helpers';

interface IProps {
  values?: { value: string; label: string }[];
  onChange: (values?: { value: string; label: string }[]) => void;
}

export const LocationMultipleInput = ({ values, onChange }: IProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce<string>(filter, 200);

  const { list, page, setPage, total } = useLocationStore(({ list, page, setPage, total }) => ({
    list,
    page,
    setPage,
    total
  }));

  const { isFetching } = useGetLocations({
    page,
    name: debouncedFilter,
    pageSize: PAGE_SIZE_50,
    sortBy: 'name'
  });

  const options = list.map(({ name, id, type }) => ({
    label: type === 'airport' ? name.split(', ')[0] : name,
    value: `${type}_${id}`
  }));

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1 && onChange) {
      const singleAutoSetOption = filteredOptions[0];
      onChange([
        {
          value: singleAutoSetOption?.value,
          label: singleAutoSetOption?.label
        }
      ]);
    }
  };

  return (
    <Select
      allowClear
      style={{ width: '100%' }}
      listHeight={180}
      mode="multiple"
      labelInValue
      maxTagCount="responsive"
      placeholder="Select locations"
      options={options}
      size={CONTROL_SIZE}
      showSearch
      optionFilterProp="label"
      loading={isFetching}
      onSearch={(value) => {
        setFilter(value);
        setPage(1);
      }}
      onClear={() => {
        setFilter('');
        setPage(1);
      }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.target as HTMLDivElement;

        if (target.scrollTop + target.offsetHeight === target.scrollHeight && list.length < total) {
          setPage(page + 1);
        }
      }}
      onBlur={handleBlur}
      value={values}
      onChange={(values) => onChange(values)}
    />
  );
};
