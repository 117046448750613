import React, { forwardRef, ReactNode, useState } from 'react';
import { Button, Flex, Tag } from 'antd';
import { DownOutlined, MailOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import { getOrderTerm, getUTCDate, getOrderIcon } from 'helpers';
import { MessageTag, useMessageStore } from 'store/messageStore';
import styled from 'styled-components';
import { FlightWidgetItem, Order } from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';

const StyledTag = styled(Tag)`
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  gap: 6px;

  span:has(.text) {
    flex-grow: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;

const LinkButton = styled(Button)`
  padding: 0 0 0 8px;
  display: flex;
  align-items: center;

  span {
    color: black;
    font-weight: 400;
  }
`;

interface IProps {
  slot?: ReactNode;
  showFlightTags?: boolean;
  showMessageTags?: boolean;
  showOrderTags?: boolean;
  total?: number;
  isSmallExpandButton?: boolean;
}

type TagItem =
  | { type: 'flight'; data: FlightWidgetItem }
  | { type: 'order'; data: Order }
  | { type: 'message'; data: MessageTag };

export const Header = ({
  slot,
  showFlightTags,
  showOrderTags,
  showMessageTags,
  total = 0,
  isSmallExpandButton = false
}: IProps) => {
  const [isTagsOpen, setTagsOpen] = useState(false);

  const { selectedOrders, setSelectedOrders } = useOrdersDashboardStore(
    ({ selectedOrders, setSelectedOrders }) => ({
      selectedOrders,
      setSelectedOrders
    })
  );
  const { selectedFlights, setSelectedFlights } = useFlightsDashboardStore(
    ({ selectedFlights, setSelectedFlights }) => ({
      selectedFlights,
      setSelectedFlights
    })
  );

  const { setSelectedMessages, selectedMessages } = useMessageStore(
    ({ selectedMessages, setSelectedMessages }) => ({
      selectedMessages,
      setSelectedMessages
    })
  );

  const renderFlightTag = (flight: FlightWidgetItem) => {
    const props = [
      flight.flightNumber,
      flight.aircraft.tailNumber,
      flight.aircraft.type,
      flight.arrivalAirport.code
    ].join(' | ');

    return (
      <StyledTag
        icon={<SendOutlined />}
        closable
        onClose={() => setSelectedFlights(selectedFlights.filter(({ id }) => id !== flight.id))}
        key={flight.id}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderOrderTag = (order: Order) => {
    const location = order.location
      ? 'code' in order.location
        ? order.location.code
        : order.location.name
      : undefined;

    const props = [
      order.type,
      location,
      getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)),
      order.customer?.name
    ]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        icon={getOrderIcon(order.type, '#00000073')}
        closable
        onClose={() =>
          setSelectedOrders(selectedOrders.filter(({ number }) => number !== order.number))
        }
        key={order.number}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderMessageTag = (message: MessageTag) => (
    <StyledTag
      icon={<MailOutlined />}
      closable
      onClose={() => setSelectedMessages(selectedMessages.filter(({ id }) => id !== message.id))}
      key={message.id}>
      <span className="text">{message.subject}</span>
    </StyledTag>
  );

  const tags = [
    ...(showFlightTags ? [{ type: 'flight', data: selectedFlights }] : []),
    ...(showOrderTags ? [{ type: 'order', data: selectedOrders }] : []),
    ...(showMessageTags ? [{ type: 'message', data: selectedMessages }] : [])
  ];

  const totaltags = tags.reduce((total, tag) => total + tag.data.length, 0);

  const renderTag = (item: TagItem) => {
    switch (item.type) {
      case 'flight':
        return renderFlightTag(item.data);
      case 'order':
        return renderOrderTag(item.data);
      case 'message':
        return renderMessageTag(item.data);
      default:
        return null;
    }
  };

  return (
    <>
      {total > 0 && slot}
      {isTagsOpen ? (
        <>
          {tags.flatMap((tag) =>
            tag.data.map((data) => renderTag({ type: tag.type, data } as TagItem))
          )}
          {totaltags > 1 && (
            <LinkButton
              type="link"
              onClick={() => setTagsOpen((prev) => !prev)}
              data-testid="expand-tags">
              Hide <UpOutlined />
            </LinkButton>
          )}
        </>
      ) : (
        <Flex style={{ alignItems: 'baseline' }}>
          {totaltags > 0 && tags[0] && (
            <div style={{ maxWidth: '80%' }}>
              {renderTag({ type: tags[0].type, data: tags[0].data[0] } as TagItem)}
            </div>
          )}
          {totaltags > 1 && (
            <LinkButton
              type="link"
              onClick={() => setTagsOpen((prev) => !prev)}
              data-testid="expand-tags">
              {isSmallExpandButton ? `+${totaltags - 1}` : `${totaltags - 1} more`} <DownOutlined />
            </LinkButton>
          )}
        </Flex>
      )}
    </>
  );
};

export const HeaderWithRef = forwardRef<HTMLDivElement | null, IProps>((props, ref) => (
  <div ref={ref}>
    <Header {...props} />
  </div>
));

HeaderWithRef.displayName = 'HeaderWithRef';
