import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { omit } from 'lodash';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { MessagesFilter } from 'store/messageStore';
import { InvoiceFilter, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { AppliedMessageFilters } from '../../components/MessageFilters/AppliedMessageFilters';
import { FilterTag } from '../../components/FilterTag';
import { getDateTagValue } from 'helpers';
import { orderTypes } from 'consts/order';
import { AuditOutlined, ProfileOutlined } from '@ant-design/icons';

interface IProps {
  filter: FlightsFilter & OrdersFilter & MessagesFilter;
  setFilter: (filter: FlightsFilter & OrdersFilter & MessagesFilter) => void;
}

export const AppliedSettlementFilters = ({ filter, setFilter }: IProps) => {
  const { ordersFilter, setOrdersFilter } = useOrdersDashboardStore(
    ({ ordersFilter, setOrdersFilter }) => ({
      ordersFilter,
      setOrdersFilter
    })
  );
  const { invoiceFilter, setInvoiceFilter } = useSettlementDashboardStore(
    ({ invoiceFilter, setInvoiceFilter }) => ({
      invoiceFilter,
      setInvoiceFilter
    })
  );

  const appliedOrderFilters = useMemo(() => {
    const appliedFilters = Object.keys(ordersFilter);

    return appliedFilters.map((filterKey) => {
      let value: string | undefined;
      const key = filterKey as keyof OrdersFilter;
      const name = key === 'aircraftTailNumbers' ? 'Aircraft' : key;

      if (key === 'orderStartDate' && ordersFilter.orderStartDate) {
        value = getDateTagValue(ordersFilter.orderStartDate);
      } else if (key === 'orderEndDate' && ordersFilter.orderEndDate) {
        value = getDateTagValue(ordersFilter.orderEndDate);
      } else if (key === 'orderTypes' && ordersFilter.orderTypes?.length === orderTypes.length) {
        return;
      } else if (key === 'orderTypes') {
        value = ordersFilter.orderTypes?.join(', ');
      } else if (key === 'orderStatuses') {
        value = ordersFilter.orderStatuses?.join(', ');
      } else if (key === 'services') {
        value = ordersFilter.services?.map(({ label }) => label).join(', ');
      } else if (key === 'isInProgram' && filter.isInProgram) {
        value = 'orders included in program';
      } else {
        value = ordersFilter[key]?.toString();
      }

      const onClose = () => {
        setFilter(omit(filter, key));
        setOrdersFilter(omit(ordersFilter, key));
      };

      const icon =
        key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
          <ProfileOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return value ? (
        <FilterTag name={name} value={value} onClose={onClose} key={key} icon={icon} />
      ) : null;
    });
  }, [ordersFilter]);

  const appliedInvoiceFilters = useMemo(() => {
    const appliedFilters = Object.keys(invoiceFilter);

    return appliedFilters.map((filterKey) => {
      const key = filterKey as keyof InvoiceFilter;
      const value = invoiceFilter[key]?.toString();

      const onClose = () => {
        setFilter(omit(filter, key));
        setInvoiceFilter(omit(invoiceFilter, key));
      };

      const icon =
        key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
          <AuditOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return value ? (
        <FilterTag name={key} value={value} onClose={onClose} key={key} icon={icon} />
      ) : null;
    });
  }, [invoiceFilter]);

  return (
    <Flex gap={4}>
      <AppliedMessageFilters filter={filter} setFilter={setFilter} />
      {appliedInvoiceFilters}
      {appliedOrderFilters}
    </Flex>
  );
};
