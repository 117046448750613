import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { DATE_FORMAT } from 'consts/common';
import { Flex, Tooltip } from 'antd';
import { CreditNoteItemHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';

export const getColumns = (): ColumnsType<CreditNoteItemHydrated> => [
  {
    title: 'Received date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (_, record) =>
      record.createdAt ? getUTCDate(record.createdAt).format(DATE_FORMAT) : '-',
    width: 120
  },
  {
    title: 'Credit note item',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => record.invoiceItem.serviceName.name
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
    render: (_, record) => record.invoiceItem.unitOfMeasure.shortName,
    width: 80
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 50,
    render: (value) => <Flex justify="end">{getAmountValue(value, 4)}</Flex>
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => (
      <div style={{ display: 'flex', overflow: 'hidden', justifyContent: 'end' }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (value) => (
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'end'
        }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (value) => (
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'end'
        }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  }
];
