/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  Flight,
  FlightInput,
  FlightListResponse,
  FlightWidgetItem,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Flights<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of flights
   *
   * @tags flights
   * @name GetFlights
   * @summary Get a list of flights
   * @request GET:/flights
   * @secure
   */
  getFlights = (
    query?: {
      /** Filter by operatorId */
      operatorId?: number;
      /** Filter by aircraftId */
      aircraftId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<FlightListResponse, Error>({
      path: `/flights`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new flight
   *
   * @tags flights
   * @name AddFlight
   * @summary Create a new flight
   * @request POST:/flights
   * @secure
   */
  addFlight = (data: FlightInput, params: RequestParams = {}) =>
    this.http.request<Flight, ValidationError | Error>({
      path: `/flights`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of flights
   *
   * @tags flights
   * @name GetFlightsWidget
   * @summary Get a list of flights for dashboard widget
   * @request GET:/flights/widget
   * @secure
   */
  getFlightsWidget = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /**
       * Comma-separated list of fields to sort by, prefix with '-' for descending order. Example - https://api.epam.com/examples?sortBy=field1,-field2
       * @pattern ^(id|flightNumber|estimatedTimeArrival|estimatedTimeDeparture,-id|,-flightNumber|,-estimatedTimeArrival|,-estimatedTimeDeparture)?$
       */
      sortBy?: string;
      /** Filter by operator */
      operator?: string;
      /** Filter by tail number */
      tailNumber?: string;
      /**
       * Filter by a list of operator ids separated by comma.
       * @example "1,2,3"
       */
      operatorIds?: string;
      /**
       * Filter by a list of aircraft ids separated by comma.
       * @example "1,2,3"
       */
      aircraftIds?: string;
      /** Filter by flight number (FLT NBR) */
      flightNumber?: string;
      /** Filter by ETD range, format as 'start,end'. Example - estimatedTimeDeparture=2023-10-26, 2023-10-27 */
      estimatedTimeDeparture?: string;
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /** Filter by a list of messageIds separated by comma. Example - messageIds=AAAAAQAAGwY,AAAAAQAAAC4 etc. */
      messageIds?: string;
      /** Filter by aiport id. For example - 7245 */
      airportId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items?: FlightWidgetItem[];
        /** @format int64 */
        total?: number;
      },
      any
    >({
      path: `/flights/widget`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific flight
   *
   * @tags flights
   * @name GetFlightById
   * @summary Get a specific flight
   * @request GET:/flights/{flightId}
   * @secure
   */
  getFlightById = (flightId: number, params: RequestParams = {}) =>
    this.http.request<Flight, Error>({
      path: `/flights/${flightId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific flight
   *
   * @tags flights
   * @name UpdateFlight
   * @summary Update a specific flight
   * @request PUT:/flights/{flightId}
   * @secure
   */
  updateFlight = (flightId: number, data: FlightInput, params: RequestParams = {}) =>
    this.http.request<Flight, ValidationError | Error>({
      path: `/flights/${flightId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific flight
   *
   * @tags flights
   * @name DeleteFlight
   * @summary Delete a specific flight
   * @request DELETE:/flights/{flightId}
   * @secure
   */
  deleteFlight = (flightId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/flights/${flightId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
