import { useQuery } from '@tanstack/react-query';
import { invoicesApi } from 'services/api';
import { QUERY_KEY } from './consts';

const fetchList = async () => await invoicesApi.getInvoiceTemplates();

export const useGetInvoiceTemplates = (enabled?: boolean) =>
  useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchList,
    keepPreviousData: true,
    enabled
  });
