import { CONTROL_SIZE } from 'consts/common';
import { Table } from 'antd';
import React from 'react';
import { getRolledUpInvoiceItemsColumns } from './columns';
import { InvoiceItemHydratedWithRolledUp } from 'services/api/data-contracts';
import { getAmountValue } from 'helpers';

interface IProps {
  invoiceItem: InvoiceItemHydratedWithRolledUp;
  isLoading: boolean;
}

export const RolledUpItemsTable = ({ invoiceItem, isLoading }: IProps) => {
  const columns = getRolledUpInvoiceItemsColumns();
  const dataSource = (invoiceItem.rolledUpItems || []).map((i) => ({
    ...i,
    key: i.id,
    currency: invoiceItem.rolledUpInvoiceCurrency
  }));

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
      size={CONTROL_SIZE}
      pagination={false}
      summary={(items) =>
        items.length > 0 && (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={5} />
            <Table.Summary.Cell index={1} align="end">
              <span style={{ fontWeight: 500 }}>
                {getAmountValue(items.reduce((acc, { total }) => acc + total, 0))}
              </span>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )
      }
    />
  );
};
