import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { GMAIL_RAW_SEARCH, MESSAGES_QUERY_KEY, REFETCH_INTERVAL } from './consts';
import { useMessageStore } from 'store/messageStore';
import { messagesApi } from 'services/emailEngineApi';
import {
  MessageList,
  MessageListEntry,
  PageMessages
} from 'services/emailEngineApi/data-contracts';
import { buildDocumentQuery } from './helpers/buildDocumentQuery';
import { PAGE_SIZE_35 } from 'consts/common';
import { getUniqueEmails } from './helpers/getUniqueEmails';
import { settingsStore } from 'services/settings/SettingsStore';
import {
  getQueuedMessagesFromStorage,
  removeLinkedMessagesFromStorage
} from './helpers/localStorage';
import { LinkActionTypes, LinkEntityItem, LinkedEntityTypes } from 'services/api/data-contracts';
import { getEntityIdsFromSource } from 'queries/links/helpers/getEntityId';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';

export const useGetMessages = (shouldMessagesUpdate: boolean) => {
  const queryClient = useQueryClient();
  const {
    setList,
    setMessagesListItemsCount,
    optimisticUpdateMessage,
    messagesFilter,
    setEmailAddressOptions,
    linkedMessagesIdsFilter
  } = useMessageStore(
    ({
      setList,
      setMessagesListItemsCount,
      optimisticUpdateMessage,
      messagesFilter,
      setEmailAddressOptions,
      linkedMessagesIdsFilter
    }) => ({
      setList,
      setMessagesListItemsCount,
      optimisticUpdateMessage,
      messagesFilter,
      setEmailAddressOptions,
      linkedMessagesIdsFilter
    })
  );

  const mailboxConfig = settingsStore.getMailboxSettings();
  const filterQuery = buildDocumentQuery(
    messagesFilter,
    linkedMessagesIdsFilter,
    mailboxConfig?.mailbox_flags
  );
  const searchQuery = {
    gmailRaw: GMAIL_RAW_SEARCH
  };
  const linksMutation = useUpdateMessageLinks();

  const requestBody = {
    search: searchQuery,
    ...(filterQuery !== null ? filterQuery : {})
  };

  const fetchMessages = async ({ pageParam = 0 }) => {
    const queryParams = {
      page: pageParam,
      pageSize: PAGE_SIZE_35,
      documentStore: true,
      exposeQuery: true
    };

    try {
      const response = await messagesApi.postV1AccountAccountSearch(
        mailboxConfig?.mailbox_name || '',
        requestBody,
        queryParams
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }
  };

  return useInfiniteQuery<MessageList, unknown, MessageList>({
    queryKey: [MESSAGES_QUERY_KEY, messagesFilter, mailboxConfig, linkedMessagesIdsFilter],
    queryFn: fetchMessages,
    getNextPageParam: (lastPage, allPages) => {
      const lastFetchedPage = allPages[allPages.length - 1];
      const { page, pages } = lastFetchedPage;
      return (page as number) < (pages as number) - 1 ? (page as number) + 1 : undefined;
    },
    onSuccess: async (data) => {
      let messages: PageMessages = [];

      if (data) {
        const queued = getQueuedMessagesFromStorage();
        const arrived = data.pages
          .flatMap((page) => page.messages ?? [])
          .filter((message) => message !== undefined);

        const newMessagesToLink = arrived.filter((message) =>
          queued.some((queuedMessage) => queuedMessage.messageId === message.messageId)
        );

        const newMessagesToLinkWithParentRef = newMessagesToLink.map((message) => {
          const queuedMessage = queued.find(
            (queuedMessage) => queuedMessage.messageId === message.messageId
          );
          return {
            ...message,
            referenceId: queuedMessage?.referenceId,
            source: queuedMessage?.source
          };
        });

        try {
          await Promise.all(
            /**
             * @description do not use bulk source update for referenced messages as source may differ
             */
            newMessagesToLinkWithParentRef.map(async ({ id, source }) => {
              if (id) {
                await linksMutation.mutateAsync({
                  action: LinkActionTypes.Link,
                  target: [
                    {
                      entityType: LinkedEntityTypes.Message,
                      entityId: id
                    }
                  ],
                  source: source as LinkEntityItem[]
                });

                const filterSource = getEntityIdsFromSource(source as LinkEntityItem[]);
                await queryClient.invalidateQueries({ queryKey: ['links', filterSource] });
              }
            })
          );

          removeLinkedMessagesFromStorage(newMessagesToLink);

          messages = arrived.map((message) => {
            const updated = newMessagesToLink.find((m) => m.messageId === message.messageId);

            return message.id === optimisticUpdateMessage?.id
              ? (optimisticUpdateMessage as MessageListEntry)
              : updated
                ? {
                    ...updated,
                    flags: updated?.flags ? [...updated?.flags, 'linked'] : ['linked']
                  }
                : (message as MessageListEntry);
          });
          setList(messages);
          setMessagesListItemsCount(data.pages[data.pages.length - 1]?.total);
          const emailAddresses = getUniqueEmails(messages);
          setEmailAddressOptions(emailAddresses);
        } catch (error) {
          console.log(error);
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: !!mailboxConfig,
    refetchInterval: shouldMessagesUpdate ? REFETCH_INTERVAL : false,
    refetchOnWindowFocus: false
  });
};
