import { create } from 'zustand';
import { Flight, LinksResponse } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { sortByDate } from './helpers';

type FlightStore = {
  current?: Partial<Flight>;
  setCurrent: (current?: Partial<Flight>) => void;
  list: Flight[];
  setList: (list: Flight[]) => void;
  links: LinksResponse;
  setLinks: (links: LinksResponse) => void;
};

export const useFlightStore = create<FlightStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      const sortedList = sortByDate(list, 'createdAt');
      set({ list: sortedList });
    },
    links: {
      orders: [],
      messages: []
    },
    setLinks: (links) => {
      set({ links });
    }
  }))
);
