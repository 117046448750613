import React, { useMemo } from 'react';
import { Button, Flex, Form, FormListOperation, Input, Tooltip } from 'antd';
import {
  Airport,
  City,
  Country,
  SpecialParameter,
  SpecialParameterAlias
} from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { DeleteOutlined } from '@ant-design/icons';
import { SpecialParameterTypeSelect } from '../SpecialParameterTypeSelect/SpecialParameterTypeSelect';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { AircraftInput } from 'components/AircraftInput/AircraftInput';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { FlightTypeInput } from 'components/FlightTypeInput/FlightTypeInput';
import { FlightPurposeInput } from 'components/FlightPurposeInput/FlightPurposeInput';
import { OrderInput } from '../OrderInput/OrderInput';
import { ContractInput } from 'components/ContractInput/ContractInput';

let ListOperation: FormListOperation;

interface IProps {
  listItemKey: number;
  remove: typeof ListOperation.remove;
  specialParameters?: SpecialParameter[];
  typesFilter?: (alias: SpecialParameterAlias) => boolean;
}

export const SpecialParameterForm = ({
  listItemKey,
  remove,
  specialParameters = [],
  typesFilter = () => true
}: IProps) => {
  const alias = specialParameters?.[listItemKey]?.alias;
  const entity = specialParameters?.[listItemKey]?.entity;

  const aircraftApiFilter = useMemo(
    () => ({
      operatorId: specialParameters.find((i) => i?.alias === SpecialParameterAlias.Operator)
        ?.entityId
    }),
    [specialParameters]
  );

  const inputComponent = useMemo(() => {
    const label = listItemKey === 0 ? 'Values' : '';

    switch (alias) {
      case SpecialParameterAlias.Location:
        const initialFilter =
          entity && 'code' in entity ? entity?.code : (entity as City | Country | Airport)?.name;

        return (
          <LocationInput
            name={[listItemKey, 'entityId']}
            label={label}
            required
            isNumberValue
            initialFilter={entity ? initialFilter : ''}
          />
        );
      case SpecialParameterAlias.Order:
        return <OrderInput name={[listItemKey, 'entityId']} label={label} required isNumberValue />;
      case SpecialParameterAlias.Aircraft:
        return (
          <AircraftInput
            name={[listItemKey, 'entityId']}
            label={label}
            required
            apiFilter={aircraftApiFilter}
          />
        );
      case SpecialParameterAlias.Customer:
        return <CounterpartyInput name={[listItemKey, 'entityId']} label={label} required />;
      case SpecialParameterAlias.Contract:
        return (
          <ContractInput
            name={[listItemKey, 'entityId']}
            counterpartyId={
              specialParameters.find((i) => i.alias === SpecialParameterAlias.Customer)?.entityId
            }
            label={label}
          />
        );
      case SpecialParameterAlias.FlightType:
        return <FlightTypeInput name={[listItemKey, 'entityId']} label={label} required />;
      case SpecialParameterAlias.FlightPurpose:
        return <FlightPurposeInput name={[listItemKey, 'entityId']} label={label} required />;
      case SpecialParameterAlias.Operator:
        return (
          <CounterpartyInput
            name={[listItemKey, 'entityId']}
            label={label}
            required
            isOperator
            onChange={() => {
              const aircraftParameter = specialParameters?.find(
                (i) => i.alias === SpecialParameterAlias.Aircraft
              );

              if (aircraftParameter) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                aircraftParameter.entityId = undefined;
              }
            }}
          />
        );
    }
  }, [alias, aircraftApiFilter]);

  return (
    <Flex gap={8} align="flex-start">
      <SpecialParameterTypeSelect
        name={[listItemKey, 'alias']}
        specialParameters={specialParameters}
        isFirst={listItemKey === 0}
        typesFilter={typesFilter}
        required
      />

      {alias ? (
        <>
          <div style={{ width: 'max(150px, 13vw)' }}>{inputComponent}</div>

          <Form.Item
            name={[listItemKey, 'notes']}
            style={{ width: '140px' }}
            label={listItemKey === 0 ? 'Note' : ''}>
            <Input size={CONTROL_SIZE} />
          </Form.Item>

          <Tooltip title="Delete">
            <Button
              icon={<DeleteOutlined />}
              size={CONTROL_SIZE}
              type="link"
              className="icon-button"
              style={{ marginTop: `${listItemKey === 0 ? 25 : 3}px`, color: 'rgba(0, 0, 0, 0.45)' }}
              data-testid="delete-special-parameter"
              onClick={() => {
                remove(listItemKey);
              }}
            />
          </Tooltip>
        </>
      ) : (
        <span style={{ width: '370px' }} />
      )}
    </Flex>
  );
};
