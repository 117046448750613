import React from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { ConfigProvider, Empty, Table } from 'antd';
import { CreditNoteItem, Payment } from 'services/api/data-contracts';
import { getColumns } from './columns';
import styled from 'styled-components';

const StyledTable = styled(
  Table<
    CreditNoteItem & {
      payment?: Payment;
    }
  >
)`
  .ant-spin-nested-loading {
    padding-bottom: 8px;
    overflow-x: hidden;
  }

  .ant-table-cell {
    align-content: flex-start;
  }
`;

interface IProps {
  creditNoteItems?: (CreditNoteItem & {
    payment?: Payment;
  })[];
}

export const CreditNoteItemsTable = ({ creditNoteItems = [] }: IProps) => {
  const dataSource = creditNoteItems.map((i) => ({ ...i, key: i.id }));
  const columns = getColumns();

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Credit note items not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledTable
        dataSource={dataSource}
        columns={columns}
        size={CONTROL_SIZE}
        pagination={false}
      />
    </ConfigProvider>
  );
};
