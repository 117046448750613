import { createContext } from 'react';

export enum DashboardType {
  'Dispatcher' = 'Dispatcher',
  'Settlement' = 'Settlement',
  'Balance' = 'Balance'
}

export const DashboardContext = createContext<{ type: DashboardType }>({
  type: DashboardType.Dispatcher
});

export const DASHBOARD_TOP_PADDING = 169;
export const LIST_FOOTER_BULK_BLOCK_HEIGHT = 57;
