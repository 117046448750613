import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, FormListOperation, InputNumber, Select, Tooltip } from 'antd';
import { InvoiceItemHydrated, InvoiceType } from 'services/api/data-contracts';
import { CONTROL_SIZE, SERVER_DATE_FORMAT } from 'consts/common';
import { DeleteOutlined } from '@ant-design/icons';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';
import { VatRateInput } from 'components/VatRateInput/VatRateInput';
import { useInvoiceStore } from 'store/invoiceStore';
import { FormInstance } from 'antd/es/form';
import { getAmountValue, getUTCDate, round } from 'helpers';
import { PriceInput } from './PriceInput';
import { AgentFeeInput } from './AgentFeeInput';
import { useWatch } from 'antd/es/form/Form';
import { OptionType } from './InvoiceItemsForm';
import { isNumber } from 'lodash';

let ListOperation: FormListOperation;

interface IProps {
  formListItemKey: number;
  invoiceItems: Partial<InvoiceItemHydrated>[];
  remove: typeof ListOperation.remove;
  form: FormInstance<{ invoiceItems: InvoiceItemHydrated[] }>;
  agentFeeOptions: OptionType[];
  setAgentFeeOptions: React.Dispatch<React.SetStateAction<OptionType[]>>;
}

export const InvoiceItemForm = ({
  formListItemKey,
  invoiceItems,
  remove,
  form,
  setAgentFeeOptions,
  agentFeeOptions
}: IProps) => {
  const [unitOfMeasureShortName, setUnitOfMeasureShortName] = useState<string | undefined>(
    undefined
  );
  const isOwnPrice = useWatch(['invoiceItems', formListItemKey, 'isOwnPrice'], form);
  const ownPriceId = useWatch(['invoiceItems', formListItemKey, 'ownPriceId'], form);
  const serviceNameId = useWatch(['invoiceItems', formListItemKey, 'serviceNameId'], form);
  const orderServiceId = useWatch(['invoiceItems', formListItemKey, 'orderServiceId'], form);

  const { isIssuedInvoice, services, invoiceSupplyDate, payerContractId } = useInvoiceStore(
    ({ current, services }) => ({
      isIssuedInvoice: current?.type === InvoiceType.Issued,
      invoiceSupplyDate: current?.supplyDate,
      payerContractId: current?.contractId,
      services
    })
  );
  const currentInvoiceItem = useMemo(
    () => invoiceItems[formListItemKey],
    [invoiceItems, formListItemKey]
  );

  useEffect(() => {
    setUnitOfMeasureShortName(currentInvoiceItem?.unitOfMeasure?.shortName || undefined);
  }, [invoiceItems]);

  const setOwnPriceProps = (price?: number, ownPriceId?: number) => {
    if (isNumber(price)) {
      form.setFieldValue(['invoiceItems', formListItemKey, 'price'], price);

      const quantity = form.getFieldValue(['invoiceItems', formListItemKey, 'quantity']);
      if (isNumber(quantity)) {
        form.setFieldValue(['invoiceItems', formListItemKey, 'amount'], round(quantity * price));
      }
    }

    form.setFieldValue(['invoiceItems', formListItemKey, 'ownPriceId'], ownPriceId);
  };

  const getLabel = (label: string) => (formListItemKey === 0 ? label : '');

  const invoiceSupplyDateOrCurrent = invoiceSupplyDate
    ? getUTCDate(invoiceSupplyDate).format(SERVER_DATE_FORMAT)
    : getUTCDate().format(SERVER_DATE_FORMAT);

  return (
    <div>
      <Flex align="start">
        <Form.Item name={[formListItemKey, 'id']} hidden />

        <AgentFeeInput
          getLabel={getLabel}
          formListItemKey={formListItemKey}
          agentFeeOptions={agentFeeOptions}
          setAgentFeeOptions={setAgentFeeOptions}
        />

        <div style={{ flexBasis: 190, overflow: 'hidden', flexGrow: 1 }}>
          <ServiceNameInput
            name={[formListItemKey, 'serviceNameId']}
            label={getLabel('Item')}
            required
            onChange={(id, unitOfMeasure) => {
              setUnitOfMeasureShortName(unitOfMeasure.shortName);
              const invoiceItems = [...form.getFieldValue('invoiceItems')];
              invoiceItems[formListItemKey] = {
                ...invoiceItems[formListItemKey],
                unitOfMeasureId: unitOfMeasure.id
              };

              form.setFieldsValue({
                invoiceItems
              });

              if (isOwnPrice && isIssuedInvoice) {
                form.validateFields([['invoiceItems', formListItemKey, 'price']]);
              }
            }}
          />
        </div>

        <Form.Item
          label={getLabel('Service')}
          name={[formListItemKey, 'orderServiceId']}
          style={{ flexBasis: 130, overflow: 'hidden', flexGrow: 1 }}>
          <Select
            size={CONTROL_SIZE}
            options={services?.map((service) => ({
              label: `${service.order.number} - ${service.service.name}`,
              value: service.id
            }))}
            placeholder="Select service"
            popupMatchSelectWidth={false}
          />
        </Form.Item>

        {!isIssuedInvoice && (
          <div style={{ flexBasis: 182, overflow: 'hidden' }}>
            <ServiceNameInput name={[formListItemKey, 'outNameId']} label={getLabel('Out name')} />
          </div>
        )}

        <Form.Item
          label={getLabel('Quantity')}
          name={[formListItemKey, 'quantity']}
          rules={[{ required: true, message: 'Is required' }]}
          style={{ flexBasis: 122, overflow: 'hidden' }}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.0000"
            precision={4}
            style={{ width: '100%' }}
            min={0}
            formatter={(value) => {
              const coercedNumber = Number(value);
              return coercedNumber.toString();
            }}
          />
        </Form.Item>

        <PriceInput
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          payerContractId={payerContractId!}
          serviceNameId={serviceNameId}
          orderServiceId={orderServiceId}
          invoiceSupplyDate={invoiceSupplyDateOrCurrent}
          isIssuedInvoice={isIssuedInvoice}
          isOwnPrice={!!isOwnPrice}
          onChange={setOwnPriceProps}
          label={getLabel('Price')}
          itemName={formListItemKey}
          ownPriceId={ownPriceId}
        />

        <Form.Item
          label={getLabel('Amount')}
          name={[formListItemKey, 'amount']}
          rules={[{ required: !isIssuedInvoice, message: 'Is required' }]}
          style={{ flexBasis: 122, overflow: 'hidden' }}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.00"
            style={{ width: '100%' }}
            precision={2}
            min={0}
            disabled={isIssuedInvoice && (isOwnPrice ? !!ownPriceId : true)}
          />
        </Form.Item>

        <div style={{ flexBasis: 84, overflow: 'hidden' }}>
          <VatRateInput name={[formListItemKey, 'vatRateId']} label={getLabel('VAT, %')} required />
        </div>

        <Tooltip title="Delete the row">
          <Button
            icon={<DeleteOutlined />}
            size={CONTROL_SIZE}
            type="link"
            className="icon-button"
            data-testid="delete-row-btn"
            style={{ marginTop: formListItemKey === 0 ? '24px' : 0 }}
            disabled={invoiceItems.length <= 1}
            onClick={() => {
              remove(formListItemKey);
            }}
          />
        </Tooltip>
      </Flex>

      <Flex
        gap={32}
        justify={unitOfMeasureShortName ? 'space-between' : 'end'}
        style={{ marginRight: 24 }}>
        {unitOfMeasureShortName && (
          <Flex style={{ marginLeft: isIssuedInvoice ? 70 : 22 }}>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)', padding: '0 4px' }}>UOM</span>
            <span>{unitOfMeasureShortName}</span>
          </Flex>
        )}

        {(!!currentInvoiceItem?.amount ||
          !!currentInvoiceItem?.vatAmount ||
          !!currentInvoiceItem?.total) && (
          <Flex gap={32} justify="end" style={{ fontSize: 12 }}>
            {[
              {
                label: 'VAT amount',
                value: getAmountValue(currentInvoiceItem?.vatAmount) || '-'
              },
              {
                label: 'Total',
                value: getAmountValue(currentInvoiceItem?.total) || '-'
              }
            ].map((prop) => (
              <span key={prop.label}>
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{prop.label}</span> {prop.value}
              </span>
            ))}
          </Flex>
        )}
      </Flex>
    </div>
  );
};
