import { LinkEntityItem, LinkedEntityTypes } from 'services/api/data-contracts';
import { filter, uniq } from 'lodash';

export const getSourceLinks = ({
  flightIds,
  orderNumbers,
  invoiceIds
}: {
  flightIds?: number[];
  orderNumbers?: string[];
  invoiceIds?: number[];
}): LinkEntityItem[] => [
  ...uniq(filter(flightIds || [], Boolean)).map((entityId) => ({
    entityType: LinkedEntityTypes.Flight,
    entityId
  })),
  ...uniq(filter(orderNumbers || [], Boolean)).map((entityId) => ({
    entityType: LinkedEntityTypes.Order,
    entityId
  })),
  ...uniq(filter(invoiceIds || [], Boolean)).map((entityId) => ({
    entityType: LinkedEntityTypes.Invoice,
    entityId
  }))
];
