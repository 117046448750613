import React, { useMemo } from 'react';
import { Button, Empty, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { PaymentPurposeWithContract, getColumns } from './columns';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { PaymentPurposeEditModeBase, usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { PaymentFullyHydrated } from 'services/api/data-contracts';
import { useDeletePaymentPurpose } from 'queries/paymentPurpose/useDeletePaymentPurpose';
import { usePaymentStore } from 'store/paymentStore';
import { useDrawerStore } from 'store/drawerStore';

const StyledTableWrapper = styled('div')`
  .ant-spin-container {
    height: fit-content !important;
  }

  .ant-table-tbody {
    padding: 6px !important;
  }

  .ant-table-tbody > tr {
    height: 35px;
  }

  .ant-table-tbody > tr > td {
    padding: 6px !important;
  }

  .ant-table-thead > tr > th {
    padding: 6px !important;
    background: #fafafa;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-footer {
    background: white !important;
    margin-left: -8px;
  }
`;

export const PaymentPurposesView = () => {
  const { current: payment } = usePaymentStore(({ current }) => ({
    current: current as PaymentFullyHydrated
  }));

  const { isCreationMode, setCreationMode, setCurrent, setEditModeBase } = usePaymentPurposeStore(
    ({ isCreationMode, setCreationMode, setCurrent, setEditModeBase }) => ({
      isCreationMode,
      setCreationMode,
      setCurrent,
      setEditModeBase
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const deleteMutation = useDeletePaymentPurpose(undefined, 'payment');

  const dataSource = useMemo(() => {
    if (payment?.paymentPurposes) {
      return payment.paymentPurposes.map((purpose) => ({
        ...purpose,
        key: purpose.id
      }));
    } else {
      return [];
    }
  }, [payment]);

  const handleDelete = (paymentPurpose: PaymentPurposeWithContract) => {
    deleteMutation.mutate(paymentPurpose.id);
  };

  const handleOpen = (paymentPurpose: PaymentPurposeWithContract) => {
    setCurrent(paymentPurpose);
    setDrawerOpen('paymentPurpose');
    setEditModeBase(PaymentPurposeEditModeBase.PAYMENT);
  };

  const columns = useMemo(
    () =>
      getColumns(handleDelete, handleOpen).filter(
        (column) =>
          column.key !== 'paidAmountInInvoiceCurrency' ||
          dataSource.some((item) => Boolean(item.paidAmountInInvoiceCurrency))
      ),
    [dataSource, handleDelete]
  );
  const rows = sortBy(dataSource, 'createdAt');

  const addNewButton = (type: 'link' | 'default') => {
    if (type === 'link' && (payment.advance === 0 || !payment.posted || isCreationMode)) {
      return null;
    }

    return (
      <Button
        type={type}
        size={CONTROL_SIZE}
        disabled={!payment?.id || payment.advance === 0 || !payment.posted}
        onClick={() => {
          setCreationMode(true);
        }}>
        {type === 'link' && '+ '}Add an invoice
      </Button>
    );
  };

  if (!payment?.paymentPurposes?.length) {
    return (
      !isCreationMode && (
        <Empty description="Payments have not been added yet" image={Empty.PRESENTED_IMAGE_SIMPLE}>
          {addNewButton('default')}
        </Empty>
      )
    );
  }

  return (
    <StyledTableWrapper>
      <Table
        columns={columns}
        dataSource={rows}
        size={CONTROL_SIZE}
        pagination={false}
        footer={() => addNewButton('link')}
      />
    </StyledTableWrapper>
  );
};
