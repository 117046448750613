import { useQuery } from '@tanstack/react-query';
import { linksApi } from 'services/api';
import { NON_EXISTENT_MESSAGE_ID, QUERY_KEY } from './consts';
import { useFlightStore } from 'store/flightStore';
import { useInvoiceStore } from 'store/invoiceStore';
import { useMessageStore } from 'store/messageStore';
import { useDashboardStore } from 'store/dashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';

export interface IFilter {
  orderNumbers?: string;
  flightIds?: string;
  invoiceIds?: string;
  messageIds?: string;
}

export const fetchLinks = async (filter: IFilter) => await linksApi.getEntityLinks(filter);

export const useGetLinks = (filter: IFilter, enabled: boolean) => {
  const { setLinks: setFlightLinks } = useFlightStore(({ setLinks }) => ({
    setLinks
  }));
  const { selectedFlights } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlights
  }));
  const { selectedOrders } = useOrdersDashboardStore(({ selectedOrders }) => ({ selectedOrders }));
  const { setLinks: setInvoiceLinks } = useInvoiceStore(({ setLinks }) => ({
    setLinks
  }));
  const { setList, setLinkedMessagesIdsFilter } = useMessageStore(
    ({ setList, setLinkedMessagesIdsFilter }) => ({
      setList,
      setLinkedMessagesIdsFilter
    })
  );
  const { isFilterByFlights, isFilterByOrders } = useDashboardStore(({ linkFilters }) => ({
    isFilterByFlights: linkFilters.includes('flights-messages') && selectedFlights.length > 0,
    isFilterByOrders: linkFilters.includes('orders-messages') && selectedOrders.length > 0
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchLinks(filter),
    onSuccess: (response) => {
      if (response?.data && !!filter.flightIds?.length) {
        setFlightLinks(response.data);
      }

      if (response?.data && !!filter.invoiceIds?.length) {
        setInvoiceLinks(response.data);
      }

      if (response?.data && (isFilterByFlights || isFilterByOrders) && !filter.messageIds?.length) {
        if (response.data.messages?.length) {
          setLinkedMessagesIdsFilter(response.data.messages);
        } else {
          setList([]);
          /* Setting unrelevant id is required due to continues auto refetch of messages by refetchInterval.
          Related bug: https://jiraeu.epam.com/browse/SNNAIR-2456
          Manual disabling of useGetMessages breaks the logic of declarative query approach.
          */
          setLinkedMessagesIdsFilter([NON_EXISTENT_MESSAGE_ID]);
        }
      }
    },
    enabled
  });
};
