import { ColumnsType } from 'antd/es/table';
import { RebateHydrated, RebateType } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import { getAmountValue, getUTCDate } from 'helpers';
import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

interface DecoratedText {
  $isNotActive: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.$isNotActive ? '#00000073' : 'inherit')};
`;

export const getRebateColumns = (): ColumnsType<RebateHydrated> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => (
      <DecoratedText
        $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
        {value}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.name, b.name),
    ellipsis: true
  },
  {
    title: 'Service name',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => (
      <DecoratedText
        $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
        {record.serviceName.name || '-'}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.serviceName.name, b.serviceName.name),
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => (
      <DecoratedText
        $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
        {record.customer.name || '-'}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.customer.name, b.customer.name),
    ellipsis: true
  },
  {
    title: '',
    dataIndex: 'currency',
    key: 'currency',
    width: 50,
    render: (_, record) => (
      <DecoratedText
        $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
        {record.currency || '%'}
      </DecoratedText>
    )
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value, record) => {
      const amount =
        getAmountValue(
          value *
            ([RebateType.AgentFeeDiscount, RebateType.InvoiceItemDiscount].includes(record.type)
              ? -1
              : 1)
        ) || '-';

      return (
        <DecoratedText
          $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
          {amount}
        </DecoratedText>
      );
    },
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'uom',
    key: 'uom',
    width: 50,
    render: (_, record) =>
      record.unitOfMeasure ? (
        <Tooltip title={record.unitOfMeasure.shortName}>
          <DecoratedText
            $isNotActive={!record.isActive || getUTCDate(record.endDate).isBefore(dayjs())}>
            {record.unitOfMeasure.shortName || ''}
          </DecoratedText>
        </Tooltip>
      ) : (
        '-'
      ),
    ellipsis: true
  }
];
