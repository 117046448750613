import React from 'react';
import { Checkbox, Flex, Input, Select, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { RangeValue } from 'store/helpers/types';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { OrderType } from 'services/api/data-contracts';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { orderStatuses, orderTypes } from 'consts/order';
import { getSelectOptions } from 'helpers';
import { ServicesMultipleInput } from 'components/ServicesMultipleInput/ServicesMultipleInput';

interface IProps {
  onChange: (
    name: string,
    value:
      | string
      | string[]
      | RangeValue
      | OrderType[]
      | { value: number; label: string }[]
      | boolean
  ) => void;
  filter: OrdersFilter;
}

export const OrderFilters = ({ onChange, filter }: IProps) => (
  <>
    <Flex gap={20} justify="space-between" style={{ width: '100%', marginBottom: '12px' }}>
      <div style={{ flexBasis: '220px' }}>
        <Tooltip trigger={['focus', 'hover']} title="Location">
          <Input
            size={CONTROL_SIZE}
            placeholder="All locations"
            name="location"
            data-testid="location-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.location}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flex: 1 }}>
        <Tooltip trigger={['focus', 'hover']} title="Payer">
          <Input
            size={CONTROL_SIZE}
            placeholder="All payers"
            name="payer"
            data-testid="payer-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.payer}
            style={{ width: '100%' }}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flexBasis: '220px' }}>
        <Tooltip trigger={['focus', 'hover']} title="Supplier">
          <Input
            size={CONTROL_SIZE}
            placeholder="All suppliers"
            name="supplier"
            data-testid="supplier-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.supplier}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flex: 1 }}>
        <Tooltip trigger={['focus', 'hover']} title="End date">
          <StyledDateRangePicker
            placeholder={['End from', 'To']}
            value={filter.orderEndDate}
            name="orderEndDate"
            testid="end-date-input"
            onChange={(value) => {
              onChange('orderEndDate', value);
            }}
            format={DATE_FORMAT}
            onSetDate={(value) => {
              onChange('orderEndDate', value);
            }}
          />
        </Tooltip>
      </div>
    </Flex>

    <Flex gap={20} justify="space-between" style={{ width: '100%', marginTop: 12 }}>
      <div style={{ flexBasis: '220px' }}>
        <Tooltip trigger={['focus', 'hover']} title="Order number">
          <Input
            size={CONTROL_SIZE}
            placeholder="All order numbers"
            name="orderNumbers"
            data-testid="order-number-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.orderNumbers}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flex: 1 }}>
        <Tooltip trigger={['focus', 'hover']} title="Status">
          <Select
            size={CONTROL_SIZE}
            placeholder="All statuses"
            style={{ width: '100%' }}
            options={orderStatuses.map((status) => ({
              value: status,
              label: status
            }))}
            optionFilterProp="label"
            mode="multiple"
            maxTagCount="responsive"
            listHeight={200}
            value={filter.orderStatuses}
            onChange={(value) => {
              onChange('orderStatuses', value);
            }}
            allowClear
          />
        </Tooltip>
      </div>

      <div style={{ flexBasis: '220px' }}>
        <Tooltip trigger={['focus', 'hover']} title="Aircraft">
          <Input
            size={CONTROL_SIZE}
            placeholder="All aircrafts"
            name="aircraftTailNumbers"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.aircraftTailNumbers}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flex: 1 }}>
        <Tooltip trigger={['focus', 'hover']} title="Arrival or departure flight numbers">
          <Input
            size={CONTROL_SIZE}
            placeholder="All flight numbers"
            name="flightNumbers"
            data-testid="flight-number-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.flightNumbers}
            style={{ width: '100%' }}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>
    </Flex>

    <Flex gap={20} style={{ marginTop: 12 }}>
      <Tooltip trigger={['focus', 'hover']} title="Services">
        <div style={{ width: '220px' }}>
          <ServicesMultipleInput
            value={filter.services}
            onChange={(value) => {
              onChange('services', value);
            }}
          />
        </div>
      </Tooltip>
      <Checkbox.Group
        options={getSelectOptions(orderTypes)}
        data-testid="order-types-select"
        value={filter.orderTypes}
        onChange={(value) => onChange('orderTypes', value as OrderType[])}
      />
      <Checkbox
        value={filter.isInProgram}
        checked={filter.isInProgram}
        onChange={({ target: { checked } }) => onChange('isInProgram', checked)}
        data-testid="order-isInProgram-checkbox">
        Program Orders
      </Checkbox>
    </Flex>
  </>
);
